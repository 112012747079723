/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable eqeqeq */
import * as React from 'react'
//import { ColumnDef } from "@tanstack/react-table";

import { faTrash, faPlus,/*faWarning, faCircleCheck, *//*faClone, faWarning, faCircleCheck */
faClone,
faWarning,
faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack,/*, Stack */
} from "react-bootstrap";
//import { /*InvoiceFields, InvoiceImportData,*/ InvoiceKeys/*, InvoiceReport*/ } from "../../services/API/InvoiceReports";
import EditableTable, { GroupedCol }/*, { GroupedCol }*/ from "../EditableTable";
import { DeleteModalState, HandleDeleteModal, IEditReportProps, InternalKeysForEIRT/*, InvoiceKey,*/, InvoiceKey, InvoicesWithCsrdAndGroupId, TableCellOnChangeCallBack } from './editTypes';
import i18n from "../../i18n";
import { /*CellContext,*/  CellContext, ColumnDef, HeaderContext,/*, HeaderContext*/ 
} from "@tanstack/react-table";
// import { ReadFriendlyNumber,/*, StringToNumber */
// StringToNumber} from "../../lib/utils";
//import { DataService } from "../../services/DataService";
//import Field from "../fields/Field";
import { Invoice } from "../../services/API/Invoices";
import { InvoiceReport } from '../../services/API/InvoiceReports';
//import { returnColumnsBasedOnReportType } from './EditColumns';
import { ReadFriendlyNumber, StringToNumber } from '../../lib/utils';
import { StyledModal } from '../modal/StyledModal';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Contract } from '../../services/API/Contracts';
import { Customer } from '../../services/API/Customer';
//import { ReadFriendlyNumber } from '../../lib/utils';
// import { InvoiceFields, InvoiceReport } from '../../services/API/InvoiceReports';
// import { DataService } from '../../services/DataService';
// import { set } from 'date-fns';


interface IEditInvoiceTableProps{
    reportType:InternalKeysForEIRT;
    data:InvoiceReport;
    callBack:TableCellOnChangeCallBack;
    invoiceKey:keyof InvoiceKey;
    addRow:()=>void;
    deleteRow:(id:number)=>void;
    copyRow:<T>(item:T,index:number) => void;
    deleteModal:DeleteModalState;
    handleDeleteModal:HandleDeleteModal;
    contract:Contract[];
    customers:Customer[];
}
interface IEditInvoiceTableState{
  collapsedGroups:number[];
  //cols:ColumnDef<Invoice>[];
  
}
class EditInvoiceReportTable extends React.Component<IEditInvoiceTableProps,IEditInvoiceTableState>{
  constructor(props:IEditInvoiceTableProps){
    super(props)
    this._cell = this._cell.bind(this)
    this.handleCollapsingOfGroups = this.handleCollapsingOfGroups.bind(this)
    this.state = {
      collapsedGroups:[],
    //  cols:[],
    }
  }
  
  csrdCols:ColumnDef<InvoicesWithCsrdAndGroupId>[] = [
    {
      id: "customer",
      header: () => i18n.t('invoiceReport.table.headers.customer'),
      cell: (props:CellContext<InvoicesWithCsrdAndGroupId,unknown>) => this._cell(props.row.original, "customerOrgId",true,props.row.index),
      minSize: 100,
      footer: (info:HeaderContext<InvoicesWithCsrdAndGroupId,unknown>) => {
        // return <>{i18n.t('invoiceReport.table.footers.noOfRows')} {this.state.invoices.length}</>
      },
    },
    {
      id: "customerOrgId",
      header: () => i18n.t('invoiceReport.table.headers.customerOrgId'),
      minSize: 100,
      cell: (props:CellContext<InvoicesWithCsrdAndGroupId,unknown>) => this._cell(props.row.original, "customerOrgId",true,props.row.index)
    },
    {
      id: 'contractId',
      header: () => i18n.t('invoiceReport.table.headers.contractId'),
      minSize: 80,
      maxSize: 80,
      cell: (props:CellContext<InvoicesWithCsrdAndGroupId,unknown>) => this._cell(props.row.original, "contractId",true,props.row.index)
    },
    {
      id: 'invoiceNumber',
      header: () => i18n.t('invoiceReport.table.headers.invoiceNumber'),
      minSize: 100,
      maxSize: 100,
      
      cell: (props:CellContext<InvoicesWithCsrdAndGroupId,unknown>) => this._cell(props.row.original, "invoiceNumber",false,props.row.index)
    },
    {
      accessorKey: 'billingDate.formattedValue',
      header: () => i18n.t('invoiceReport.table.headers.date'),
      minSize: 100,
      maxSize: 100,
      cell: (props:CellContext<InvoicesWithCsrdAndGroupId,unknown>) => this._cell(props.row.original, "billingDate",false,props.row.index)
    },
    {
      id: "sumExclMoms",
      header: () => i18n.t('invoiceReport.table.headers.sum'),
      minSize: 80,
      cell: (props:CellContext<InvoicesWithCsrdAndGroupId,unknown>) => {
        if(this.props.data && this.props.invoiceKey){
          const buff = this.props.data.csrdGroup 
          
          const sum = ReadFriendlyNumber(
            buff
            ?.filter((inv:InvoicesWithCsrdAndGroupId) => inv.groupId == props.row.original.groupId)
            .reduce((prev:any, curr:InvoicesWithCsrdAndGroupId) => {
              return prev + StringToNumber(curr.rowSumExclMoms.toString())
            }, 0)
          )
          //console.log('HÄR ÄR BUFF I COLUMNDEFINITONEN',invoiceKey,buff,sum)
          return <div className="disabled-cell">
        {
          ReadFriendlyNumber(
            buff
            ?.filter((inv:any) => inv.groupId == props.row.original.groupId)
            .reduce((prev:any, curr:any) => {
              return prev + StringToNumber(curr.rowSumExclMoms.toString())
            }, 0)
          )
        }
      </div>
        }else{
         // console.log('HMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM')
          return this._cell(props.row.original, "rowSumExclMoms",false,props.row.index) 
        }
         
        
        
        //return _cell(props.row.original, "priceExcMoms",false,props.row.index)
      },
      footer: (info:HeaderContext<InvoicesWithCsrdAndGroupId,unknown>) => {
        if(this.props.data && this.props.invoiceKey){
          const buff = this.props.data.csrdGroup 
          const sum = buff?.reduce((prev:any, curr:any, i:any, self:any) => {
            const value = curr.rowSumExclMoms.toString().replaceAll(" ", "").replaceAll(',',".");
            return prev + parseFloat(value)
          }, 0.0);
          return <>{i18n.t('invoiceReport.table.footers.totalSum')} {ReadFriendlyNumber(sum)}</>
        }
        
      },
    },
    {
      id:"noOfArticles",
      header: () => i18n.t('invoiceReport.table.headers.noOfArticles'),
      minSize:40,
      maxSize:40,
      cell: (props:CellContext<InvoicesWithCsrdAndGroupId,unknown>) => {
        if(this.props.data && this.props.invoiceKey){
          const buff = this.props.data.csrdGroup 
          const groupItems = buff?.filter((inv:any) => inv.groupId == props.row.original.groupId);
        return <div className="disabled-cell">
          {
               groupItems?.length
            
          }
        </div>
        }
        
      }
    },
    {
      id:"warnings/errors",
      header: () => "",
      minSize:25,
      maxSize:25,
      cell: (props:CellContext<InvoicesWithCsrdAndGroupId,unknown>) => {
        if(this.props.data && this.props.invoiceKey){
          const buff = this.props.data.csrdGroup 
          const groupItems = buff?.filter((inv:any) => inv.groupId == props.row.original.groupId);
        const {errors,warnings} = {errors:0,warnings:0}
        // const {errors,warnings} = DataService.InvoiceReports.Validations.GetValidationStatus(groupItems, true);
        let icon = errors || warnings ? faWarning : faCircleCheck;
        let color = "#07a235";
        if(errors > 0) color = "#b30404";
        else if(warnings > 0) color = "#f8b133";
        return <>{
          <Stack direction="horizontal" gap={2} style={{height: "100%",justifyContent: "center"}}>
              <FontAwesomeIcon icon={icon} color={color} style={{height: "60%", marginRight: 6, marginLeft: 6}}/>
          </Stack>
        }</>
        }
       //return <>WARNINGS,ERRORS</>
      }
    },
     {
      accessorKey: "none",
      header: () => i18n.t('invoiceReport.table.headers.tools'),
      maxSize: 50,
      minSize: 50,
      cell: (props:CellContext<InvoicesWithCsrdAndGroupId,unknown>) => {
        return <Stack direction="horizontal" style={{height:42, justifyContent:"space-evenly"}} >
        <Button 
            className="outline-button row-btn" 
            onClick={(ev) => {
            //   const item = props.row.original;
            //   const ind = this.state.invoices.findIndex(inv => inv.groupId == item.groupId);
            //   const copyItem : InvoiceImportData = {
            //     id: -1,
            //     groupId: this.findNewGroupId(this.state.invoices),
            //     billingDate: "",
            //     contractId: item.contractId.value,
            //     customer: item.customer.value,
            //     customerOrgId: item.customerOrgId.value,
            //     invoiceNumber: "",
            //     rowSumExclMoms: "",
            //     articleQuantity: "",
            //     articlePricePerUnit: "",
            //     articleNumber: "",
            //     articleText: "",
            //     articleQuantityType: ""
            //   };
             // this.addRow({copyItem, index: ind+1});
             console.log('ADD')
            }}>
          <FontAwesomeIcon icon={faClone} />
        </Button>
        <Button 
          className="button outline-button-danger row-btn" 
          onClick={(ev) => {
            const buff:any[] | undefined = this.props.data.csrdGroup            
            const updatedRows = buff?.filter((item:any, i:number) => item.groupId != props.row.original.groupId);
            // this.setState({ collapsedGroups: this.state.collapsedGroups.filter(g => g != props.row.original.groupId) });
            // this.setInvoices(updatedRows, false);
            //setCollapsedGroups( collapsedGroups.filter(g => g != props.row.original.groupId))
            //console.log('DELETE')
            this.handleCollapsingOfGroups(props.row.original.groupId)
          }}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Stack>
      },
      footer: () => {
        return <Button tabIndex={-1} className="button add-button" style={{height:"100%",padding:0, borderRadius: 0}} onClick={this.props.addRow}>
          <FontAwesomeIcon icon={faPlus}/>
          {i18n.t('invoiceReport.table.footers.add')}
        </Button>
      },
      meta: "skipPaddingOnFooter"
    }
  ]
   groupedColumns: GroupedCol<InvoicesWithCsrdAndGroupId>[] = [
    {
      id: "articleNumber",
      header: i18n.t("invoiceReport.table.headers.articleNr"),
      cell: (props) => this._cell(props, "articleNumber",true,-1),
      minSize: "100px"
    },
    {
      id: "articleText",
      header: i18n.t("invoiceReport.table.headers.articleText"),
      cell: (props) => this._cell(props, "articleText",false,-1),
      minSize: "100px"
    },
    {
      id: "articleQuantity",
      header: i18n.t("invoiceReport.table.headers.articleQuantity"),
      cell: (props) => this._cell(props, "articleQuantity",false,-1),
      minSize: "50px"
    },
    {
      id: "articleQuantityType",
      header: i18n.t("invoiceReport.table.headers.articleQuantityType"),
      cell: (props) => this._cell(props, "articleQuantityType",false,-1),
      minSize: "30px"
    },
    {
      id: "articlePricePerUnit",
      header: i18n.t("invoiceReport.table.headers.articlePricePerUnit"),
      cell: (props) =>this._cell(props, "articlePricePerUnit",false,-1),
      minSize: "100px"
    },
    {
      id: "rowSumExclMoms",
      header: i18n.t("invoiceReport.table.headers.rowSumExclMoms"),
      minSize: "100px",
      cell: (props) => this._cell(props, "rowSumExclMoms",false,props.id),
    },
    {
      id: "nestedTools",
      minSize:"5%",
     header: "",
     cell: (row, index) => {
       return <Button 
         className="button outline-button-danger row-btn" 
         style={{ width: "100%" }}
         onClick={(ev) => {              
         // const updatedRows = this.state.invoices.filter((item, i) => item.id != row.id);
        //    this.setInvoices(updatedRows, false);
            console.log('klick en delete')
         }}>
         <FontAwesomeIcon icon={faTrash} />
       </Button>
     },
     footer: (col, groupId) => {
      return <Button tabIndex={-1} className="button add-button" style={{height:"100%",padding:0, borderRadius: 0}} 
        onClick={() => {
        //   const ind = this.state.invoices
        //     .map(inv => inv.groupId)
        //     .lastIndexOf(groupId);
        //   if(ind == -1)
        //     return;
        //   const item = this.state.invoices[ind];
        //   const copyItem : InvoiceImportData = {
        //     id: -1,
        //     groupId: groupId,
        //     billingDate: item.billingDate.value,
        //     contractId: item.contractId.value,
        //     customer: item.customer.value,
        //     customerOrgId: item.customerOrgId.value,
        //     invoiceNumber: item.invoiceNumber.value,
        //     rowSumExclMoms: "",
        //     articleQuantity: "",
        //     articlePricePerUnit: "",
        //     articleNumber: "",
        //     articleText: "",
        //     articleQuantityType: ""
        //   };
        //   this.addRow({copyItem, index: ind+1, groupId});
        console.log('klick en rad')
        }}>
        <FontAwesomeIcon icon={faPlus}/>
        {i18n.t("invoiceReport.newArticleText")}
      </Button>
    },
     meta: "skipPaddingOnFooter"
   }
  ];
  invoiceCOls:ColumnDef<Invoice>[] = [
    {
      id: "customer",
      header: () => i18n.t('invoiceReport.table.headers.customer'),
      cell: (props:CellContext<Invoice,unknown>) => this._cell(props.row.original, "customerName",true,props.row.index),
      minSize: 100,
      footer: (info:HeaderContext<Invoice,unknown>) => {
        // return <>{i18n.t('invoiceReport.table.footers.noOfRows')} {this.state.invoices.length}</>
      },
    },
    {
      id: "customerOrgId",
      header: () => i18n.t('invoiceReport.table.headers.customerOrgId'),
      minSize: 100,
      cell: (props:CellContext<Invoice,unknown>) => this._cell(props.row.original, "customerOrgId",true,props.row.index)
    },
    {
      id: 'contractId',
      header: () => i18n.t('invoiceReport.table.headers.contractId'),
      minSize: 80,
      maxSize: 80,
      cell: (props:CellContext<Invoice,unknown>) => this._cell(props.row.original, "contractId",true,props.row.index)
    },
    {
      id: 'invoiceNumber',
      header: () => i18n.t('invoiceReport.table.headers.invoiceNumber'),
      minSize: 100,
      maxSize: 100,
      
      cell: (props:CellContext<Invoice,unknown>) => this._cell(props.row.original, "invoiceNumber",false,props.row.index)
    },
    {
      accessorKey: 'billingDate.formattedValue',
      header: () => i18n.t('invoiceReport.table.headers.date'),
      minSize: 100,
      maxSize: 100,
      cell: (props:CellContext<Invoice,unknown>) => this._cell(props.row.original, "billingDate",false,props.row.index)
    },
    {
      id: "priceExcMoms",
      header: () => i18n.t('invoiceReport.table.headers.sum'),
      minSize: 80,
      cell: (props:CellContext<Invoice,unknown>) => {
        // if(!this.state.reportingArticles)
        //   return this._cell(props.row.original, "rowSumExclMoms")
        // return <div className="disabled-cell">
        //   {
        //     ReadFriendlyNumber(
        //       this.state.invoices
        //       .filter(inv => inv.groupId == props.row.original.groupId)
        //       .reduce((prev, curr) => {
        //         return prev + StringToNumber(curr.rowSumExclMoms.value)
        //       }, 0)
        //     )
        //   }
        // </div>
        //console.log('Detta är data tillgängligt i cellen',data)
        return this._cell(props.row.original, "priceExcMoms",false,props.row.index)
      },
      footer: () => {
        //onsole.log('DATAN SOM ÄR TILLGÄNGLIG I FOOTER',data)
        if(this.props.data && this.props.invoiceKey){
          //console.log('BERÄKNA NY FOOTER')
          
          const buff:any[] | undefined = this.props.data[this.props.invoiceKey] 
        const sum = buff?.reduce((prev:number, curr:Invoice, i, self) => {
          const value = curr.priceExcMoms.toString().replaceAll(" ", "").replaceAll(',',".");//.value.replaceAll(" ", "").replaceAll(',',".");
          //console.log(value,prev,data[invoiceKey],buff)
          return prev + parseFloat(value)
        }, 0.0);
        //console.log(sum)
        return <>{i18n.t('invoiceReport.table.footers.totalSum')} {ReadFriendlyNumber(sum)}</>
        }
      },
    },
    {
      id:"noOfArticles",
      header: () => i18n.t('invoiceReport.table.headers.noOfArticles'),
      minSize:40,
      maxSize:40,
      cell: (props:CellContext<Invoice,unknown>) => {
        const groupItems = 1//this.state.invoices.filter(inv => inv.groupId == props.row.original.groupId);
        return <div className="fieldWrapper">
          {
            //   groupItems.length
            groupItems
          }
        </div>
      }
    },
    {
      id:"warnings/errors",
      header: () => "",
      minSize:25,
      maxSize:25,
      cell: (props:CellContext<Invoice,unknown>) => {
        // const groupItems = this.state.invoices.filter(inv => inv.groupId == props.row.original.groupId);
        // const {errors,warnings} = DataService.InvoiceReports.Validations.GetValidationStatus(groupItems, this.state.reportingArticles);
        // let icon = errors || warnings ? faWarning : faCircleCheck;
        // let color = "#07a235";
        // if(errors > 0) color = "#b30404";
        // else if(warnings > 0) color = "#f8b133";
        // return <>{
        //   <Stack direction="horizontal" gap={2} style={{height: "100%",justifyContent: "center"}}>
        //       <FontAwesomeIcon icon={icon} color={color} style={{height: "60%", marginRight: 6, marginLeft: 6}}/>
        //   </Stack>
        // }</>
       return <>WARNINGS,ERRORS</>
      }
    },
     {
      accessorKey: "none",
      header: () => i18n.t('invoiceReport.table.headers.tools'),
      maxSize: 50,
      minSize: 50,
      cell: (props:CellContext<Invoice,unknown>) => {
        return <Stack direction="horizontal" style={{height:42, justifyContent:"space-evenly"}} >
        <Button 
            className="outline-button row-btn" 
            onClick={(ev) => {
            //   const item = props.row.original;
            //   const ind = this.state.invoices.findIndex(inv => inv.groupId == item.groupId);
            //   const copyItem : InvoiceImportData = {
            //     id: -1,
            //     groupId: this.findNewGroupId(this.state.invoices),
            //     billingDate: "",
            //     contractId: item.contractId.value,
            //     customer: item.customer.value,
            //     customerOrgId: item.customerOrgId.value,
            //     invoiceNumber: "",
            //     rowSumExclMoms: "",
            //     articleQuantity: "",
            //     articlePricePerUnit: "",
            //     articleNumber: "",
            //     articleText: "",
            //     articleQuantityType: ""
            //   };
             // this.addRow({copyItem, index: ind+1});
             this.props.copyRow(props.row.original,props.row.index)
             console.log('ADD')
            }}>
          <FontAwesomeIcon icon={faClone} />
        </Button>
        <Button 
          className="button outline-button-danger row-btn" 
          onClick={(ev) => {              
            // const updatedRows = this.state.invoices.filter((item, i) => item.groupId != props.row.original.groupId);
            // this.setState({ collapsedGroups: this.state.collapsedGroups.filter(g => g != props.row.original.groupId) });
            // this.setInvoices(updatedRows, false);
            //this.setState({deleteModal:{show:true,id:props.row.original.id}})
            this.props.handleDeleteModal(true,props.row.original.id)
            
            console.log('DELETE')
          }}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Stack>
      },
      footer: () => {
        return <Button tabIndex={-1} className="button add-button" style={{height:"100%",padding:0, borderRadius: 0}} onClick={this.props.addRow}>
          <FontAwesomeIcon icon={faPlus}/>
          {i18n.t('invoiceReport.table.footers.add')}
        </Button>
      },
      meta: "skipPaddingOnFooter"
    }
  ]

  // componentDidUpdate(prevProps: Readonly<IEditInvoiceTableProps>, prevState: Readonly<IEditInvoiceTableState>, snapshot?: any): void {
  //   if(prevProps.data !== this.props.data){
  //       console.log('VAFJSbajkbJKSBfjsbadjkvbsjkascascaccsacbjksdvbjksdbjkvbdsk')
  //       console.log(this.props.data)
  //     this.setState({cols:returnColumnsBasedOnReportType<Invoice>(this.props.reportType,this._cell,this.props.data,this.props.invoiceKey,this.handleCollapsingOfGroups) as ColumnDef<Invoice>[]})
  //   }  
  // }
 
  handleCollapsingOfGroups(groupId:number){
    //setCollapsedGroups( collapsedGroups.filter(g => g != groupId))
    this.setState({collapsedGroups:this.state.collapsedGroups.filter(g => g != groupId)})
  }
  checkKeyForTypeAheds = (key:string):boolean => {
    if(key == 'contractId' || key == 'customerOrgId' || key == 'customerName'){
      return true
    }
    else{
      return false
    }
  }
  returnOptionsForTypeAhead = (key:string):string[] =>{
    switch(key){
      case'contractId': return this.props.contract.map(x => `${x.contractId}`) 
      case 'customerOrgId': return this.props.customers.map(x => `${x.kndOrgnr}`) 
      case 'customerName':return this.props.customers.map(x => `${x.kndNamn}`)
      default: return [];
    }
  }
_cell <T extends object>(item: T, key:keyof T,readOnly:boolean,index:number):JSX.Element {
  
  const style:React.CSSProperties={height:20,fontSize:'13px !important',padding:'0.3em'}
  const value = item[key];
  const isValidDate = value instanceof Date && !isNaN(value.getTime());
  
  const handleChange = <K extends keyof T>(e: React.ChangeEvent<HTMLInputElement>, key: K) => {   
    let newValue: T[K];
      if (e.target.type === 'checkbox') {
        newValue = e.target.checked as T[K]; // Checkbox handling
      } else if (e.target.type === 'date') {
        newValue = new Date(e.target.value) as T[K]; // Date handling
      } else if(e.target.type === 'number'){
        newValue = +e.target.value as T[K]
      } 
      else {
        newValue = e.target.value as T[K]; // Default (text) handling
      }
      item[key] = newValue
      //console.log('änding i handleChange')
      this.props.callBack(item,index)
    };
  const fields: Record<string, JSX.Element> = {
      bigint: <span>{JSON.stringify(value)}</span>,
      boolean: <input style={style} type="checkbox" checked={value as boolean} readOnly={readOnly} />,
      function: <span>Function</span>, 
      number: <input className='fieldWrapper' type="number" value={value as number} readOnly={readOnly} onChange={(e) => handleChange(e,key)}/>,
      object: isValidDate ? (
          <input 
         className='fieldWrapper'
            type="date" 
            onChange={(e) => handleChange(e,key)} 
            value={(value as Date).toLocaleDateString()} 
            readOnly={readOnly}
          />
        ) : (
          <span>{JSON.stringify(value)}</span>
        ), // Handle date and other objects
      string: this.checkKeyForTypeAheds(key as string) ? (<Typeahead
      allowNew={false}
      positionFixed
      placeholder={value as string}
      //newSelectionPrefix={i18n.t("kickbackProject.add")+' '}
      //emptyLabel={`${i18n.t("kickbackProject.emptyLabel")}`}
      id={`${Math.random()}`}
      options={this.returnOptionsForTypeAhead(key as string)}
      //onBlur={blur ? (e) => this.handleTypeaheadBlur(e.target.value,item.uniqueRowId,key): undefined }
      onChange={(e) =>
       {
        //console.log(item)
        const buff =  {...item}
        //console.log(e[0])
        const res =  this.props.customers.find(x => x.kndNamn== e[0] || x.kndOrgnr.toString() == e[0])
        console.log(key !== 'contractId' && res !== undefined && 'customerOrgId' in buff && 'customerName' in buff)
        if(key !== 'contractId' && res !== undefined && 'customerOrgId' in buff && 'customerName' in buff){
         
          buff['customerName'] = res.kndNamn
          buff['customerOrgId'] = res.kndOrgnr
          const finalResult = {...buff} as T
          //console.log(buff)
          this.props.callBack(finalResult,index)
        }
       } 
      }
    >
      
    </Typeahead>): (<input className='fieldWrapper' type="text" readOnly={readOnly} onChange={(e) => handleChange(e,key)} value={value as string} />),
      symbol: <span>Symbol</span>,
      undefined: <span>Undefined all the time</span>
    };
     const fieldType = isValidDate ? 'object' : typeof value;
     console.log('dags att rendera på nytt',key,value,this.props.data)
  return (
   <>{fields[fieldType] }</>
  )
}
  render(){
    
    const buff = this.props.data.csrdGroup as InvoicesWithCsrdAndGroupId[] | undefined
      const  groupedItems =  buff?.reduce((prev:any, curr:any) => {
          const potentialGroup = prev.find((p:any) => p.groupId == curr.groupId);
          if(potentialGroup){
            potentialGroup.items = potentialGroup.items.concat(curr);
          }
          else {
            prev = prev.concat({ 
              ...curr,
              items: [curr]
             })
          }
          return prev;
        }, [] as InvoicesWithCsrdAndGroupId[]);
    return(
      <div style={{marginTop:20,background:'white',fontSize:'13px'}}>
        {
            this.props.deleteModal.show &&
            <StyledModal 
              
              onDismiss={() => { this.props.handleDeleteModal(false,-1) }}
              title={/*this.props.t('invoiceReport.successModalTitle').toString()*/'Ta bort'}
              body={
                  <div style={{alignContent:'center',flexDirection:'column',textAlign:'center'}}>
                  Är du säker på att du vill ta bort den valda raden?
                <Stack direction='horizontal' gap={3} style={{justifyContent:"center",marginTop:'15px'}}>
                  {/* <div style={{fontSize: 18, fontWeight: 500}}>{this.state.successMsg}</div> */}
                  <Button style={{width: 100}} className="button button-danger" onClick={() => this.props.deleteRow(this.props.deleteModal.id)} /*onClick={() => { this.setInvoices([], false); this.setState({ showSuccessModal: false}) }}*/>{'Ta bort'}</Button>
                  <Button style={{width: 100}} className="button button-cancel" onClick={() => this.props.handleDeleteModal(false,-1)} /*onClick={() => { this.setInvoices([], false); this.setState({ showSuccessModal: false}) }}*/>{'Stäng'}</Button>
                </Stack>
                </div>
              }
            />
          }
        {this.props.data && this.props.invoiceKey  && this.props.reportType == 'InvoicereportWithCSRD' && this.props.invoiceKey == 'csrdGroup' &&
          <EditableTable 
          columns={
           this.csrdCols// returnColumnsBasedOnReportType<InvoicesWithCsrdAndGroupId>(this.props.reportType,this._cell,this.props.data,this.props.invoiceKey,this.handleCollapsingOfGroups) 
        } 
          grouped={
              {
            groupedColumns: this.groupedColumns,
            collapsedGroups: this.state.collapsedGroups,
            onCollapseGroup: (groupId:number) => { 
            
              const groupIdExists = this.state.collapsedGroups.includes(groupId);
              const newList = groupIdExists ? 
                this.state.collapsedGroups.filter(g => g != groupId)
                :
                this.state.collapsedGroups.concat(groupId);
               this.setState({collapsedGroups:newList})//setCollapsedGroups( newList);
            },
            onCollapseAll: () => {
              if(this.props.data && this.props.invoiceKey){
                const groupNumbers = this.props.data.csrdGroup?.map((inv:any) => inv.groupId)
                
                .filter((grpId:any, i:any, self:any) => self.indexOf(grpId) == i) as number[];
              if(groupNumbers?.every((grpId:any) => this.state.collapsedGroups.includes(grpId))){
                this.setState({collapsedGroups:[]})//setCollapsedGroups([]);
              }else{
                this.setState({collapsedGroups:groupNumbers})//setCollapsedGroups( groupNumbers ?? [])  
              }
              }
              
            
            }
          }}
          
          data={groupedItems ?? []} 
          onChange={(newData) => console.log(newData)/*this.setState({invoices: newData})*/}
          />
        }
         {this.props.data && this.props.invoiceKey  && this.props.reportType == 'Invoicereport' && this.props.invoiceKey == 'invoices'  &&
               <EditableTable 
               columns={
               this.invoiceCOls
             } 
               data={this.props.data.invoices as Invoice[] ?? []} 
               onChange={(newData) => console.log(newData)/*this.setState({invoices: newData})*/}
               />
          
         }
                
        </div>
    )
  }
}

export{
    EditInvoiceReportTable
}



