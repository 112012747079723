
import Urls from "../../lib/Urls";
import { Code, FetchReturn, ReturnFetchObj, apiFetch } from "../../lib/utils";
import { TUser } from "./Users";
import { Invoice } from "./Invoices";
export type DepartmentSuccessResponse ={
  [key:string]:number;
}
export interface Department {
  id:number;
  department:string;
  supplierOrgId?:string;
  startDate?:Date|null;
  endDate?:Date|null;
  changed?:boolean;
}
export interface DepartmentReportedResponse{
  reported:boolean;
  period:string;
  cantReport:boolean;
}
export interface DepartmentFunctionArgs{
    departments?:Department[];
    id?:number;
    supplierOrgId?:string;
    deletedIds?:number[];
    supplierOrgIds?:string[];
    user?:Required<TUser>;
    reportCheck?:CheckArg;
}
export type DepartmentField ={
  values:Department[];
  isUpdated:boolean;
}
export type DepartmentDictionary ={
  [key:string]:DepartmentField
}
export type DepartmentDateKeys = Pick<Department,'startDate' | 'endDate'>

type CheckArg = {
  report:ReportType;
  periods?:string[];
  departmentId?:number;
  locale?:string;
}


export enum ReportType {
  "InvoiceReport" = 0,
  "Kickback" = 1,
  "KickbackProject" = 2,
  "NullReport" = 3,
  "Overview" = 4
}
type DepartmentKeys = 'GetDepartments' | 'GetDepartment' | 'PostUpdateDepartments' | 'DeleteDepartment' | 'GetMultipleDepartmentsPerSupplier' | 'GetUserAssignedDepartment' | 'CheckAlreadyReportedDepartment'

type ReturnTypes<T extends DepartmentKeys> =
  T extends 'GetDepartments' ? Department[] :
  T extends 'GetDepartment' ? Department|undefined :
  T extends 'PostUpdateDepartments' ? FetchReturn<{response:DepartmentSuccessResponse}> : 
  T extends 'DeleteDepartment' ? FetchReturn<{response:any}> :
  T extends 'GetMultipleDepartmentsPerSupplier' ? FetchReturn<{response:DepartmentDictionary}> :
  T extends 'GetUserAssignedDepartment' ? FetchReturn<{response:Department|undefined}> :
  T extends 'CheckAlreadyReportedDepartment'? FetchReturn<{response:{[key:string]:CompressedDepartmentReported}}>
  :
  never;


async function parseJsonResponse<T,U>(response: Response,transformFunc:(data:T) => U): Promise<U> {
  const jsonData = await response.json();
  return transformFunc(jsonData);
}
const DeleteDepartment = async ({deletedIds}:DepartmentFunctionArgs):Promise<any>/*Promise<FetchReturn<{response:any}>>*/ =>{
  try{
    const res = await apiFetch(Urls.Department + `?departmentsToRemove=${deletedIds}`, "DELETE");
    return ReturnFetchObj('Removed departments', Code.OK, {res});
    
  }catch(er){
    console.log(er)
  }
}

const GetDepartments = async ({supplierOrgId}:DepartmentFunctionArgs):Promise<Department[]> =>{
  const responese = await apiFetch(Urls.Department +`?supplierOrgId=${supplierOrgId}`);
   const result = await parseJsonResponse<Department[],Department[]>(responese,data => {
   return data.map(x => 
      { 
        const buff:Department =  {id:x.id,supplierOrgId:x.supplierOrgId,changed:x.changed,department:x.department,startDate:x.startDate == null ? null :new Date(x.startDate),endDate:x.endDate == null ? null :new Date(x.endDate)}
        return buff
     })
     
    })
   return result
}
const GetDepartment = async ({id}:DepartmentFunctionArgs):Promise<Department | undefined> =>{
  try{
      const response = await apiFetch(Urls.Department + `/Id?id=${id}`)
      if(response.ok){
        const parsedResponse = await ParseJsonResponse.ParseToObject<Department>(response);
        return parsedResponse
      }
     
  }catch(err){
    //console.log(err)
    return undefined
  }
  
}
const PostUpdateDepartments = async ({departments}:DepartmentFunctionArgs):Promise<FetchReturn<{response:DepartmentSuccessResponse}>> =>{
  
 try{
  console.log()
  const dataToSend = JSON.stringify(departments,(key:string,value:any) => {
    if(key == 'startDate'){
     
      return  new Date(value as string).toLocaleDateString('sv-SE',{year:"numeric",month:'2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit',second:'numeric'})
    }
    if(key == 'endDate' && (value !== null || undefined)){
      return  new Date(value as string).toLocaleDateString('sv-SE',{year:"numeric",month:'2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit',second:'numeric'})
    }
    return value
  })
  const response = await apiFetch(Urls.Department,'POST',dataToSend,{"Content-Type": "application/json"});
  const parsedResult = await parseJsonResponse<any,DepartmentSuccessResponse>(response,data => {
    const buffObj:DepartmentSuccessResponse = {}
    Object.entries(data).forEach(([key,value]) => {
      const val:number = value as number
      buffObj[key] = val
     })
     return buffObj   
  })
  return ReturnFetchObj('added deps', Code.OK, {response:parsedResult});
 }catch(er){
  console.error(er);
  return ReturnFetchObj('error updating/creating department', Code.ADD_ERR,{response:{}});
 }
  
  
}
const GetMultipleDepartmentsPerSupplier = async({supplierOrgIds}:DepartmentFunctionArgs):Promise<FetchReturn<{response:DepartmentDictionary}>> =>{
  try{
    const query = Urls.Department + `/GetMultipleSupplierDepartments?suppliers=${supplierOrgIds?.toString()}`
    const response = await apiFetch(query)
    const parsedResult = await ParseJsonResponse.BuildDepartmentDictionary<DepartmentDictionary>(response)
    return ReturnFetchObj('Success fetching supplier departments',Code.OK,{response:parsedResult})
  }catch(er){
    console.error(er);
    return ReturnFetchObj('error fetching supplier departments',Code.GET_ERR,{response:{}})
  }
}
const GetUserAssignedDepartment = async():Promise<FetchReturn<{response:Department | undefined}>> => {
  try{
    const query = Urls.Department + `/GetUserAssignedDepartment`
    const response = await apiFetch(query)
    const buffObj = await ParseJsonResponse.ParseToObject<Department>(response)
    console.log('insideGetUserAssignedDepartment result to return',buffObj)
    return ReturnFetchObj('Found department',Code.OK,{response:buffObj})
  }catch(err){
    console.log(err)
    return ReturnFetchObj('No departmentfound',Code.GET_ERR,{response:undefined})
  }
  
}
const CheckAlreadyReportedDepartment = async ({reportCheck}:DepartmentFunctionArgs):Promise<FetchReturn<{response:{[key:string]:CompressedDepartmentReported}}>> =>{
  try{
    if(reportCheck?.report !== 4){
      const userDepartment =  await GetUserAssignedDepartment();
      if(userDepartment.Content.response?.id){
       let buff  = {...reportCheck}
       buff.departmentId = userDepartment.Content.response?.id
     const query = Urls.Department + `/GetReportedPeriodStatus?report=${buff.report}&departmentId=${buff.departmentId}&periods=${buff.periods?.toString()}&locale=${buff.locale}`
     const response = await apiFetch(query)
     const res =  await ParseJsonResponse.GroupBy<DepartmentReportedResponse,CompressedDepartmentReported>(response,'period','Object',['period'])
       return ReturnFetchObj('Checked',Code.OK,{response:res})
      }
    }
    else{
      if(typeof reportCheck.departmentId !== 'undefined'){
        const query = Urls.Department + `/GetReportedPeriodStatus?departmentId=${reportCheck.departmentId}&report=${reportCheck.report}`
      const response = await apiFetch(query)
      const res = await ParseJsonResponse.GroupBy<DepartmentReportedResponse,CompressedDepartmentReported>(response,'period','Object')  
      return ReturnFetchObj('Checked',Code.OK,{response:res})
      }
      
    }
    return ReturnFetchObj('Checked',Code.OK,{response:{}})
 
    
  
    
  }catch(error){
    console.log(error)
    return ReturnFetchObj('ErrorChecking',Code.OK,{response:{}})
  }
}

async function ParseToObject<T>(response:Response):Promise<T>{
  const result = await response.json()
  const buff:T = structuredClone(result)
  console.log('parseResult',result,buff)
  //return result as T
  return buff 
}
async function BuildDepartmentDictionary<T>(response:Response):Promise<T>{
 const resp = await response.json()
 const result = resp.reduce((acc:DepartmentDictionary,item:any) => {
    if(!acc[item.supplier]){
      acc[item.supplier] = {values:item.departments,isUpdated:false} as DepartmentField
      return acc
    }  
    return acc
  },{})
  return result as T
}
type SortOrder = 'asc' | 'desc'
function omit<T, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> {
  const result = { ...obj };
  keys.forEach(key => {
    delete result[key];
  });
  return result;
}


async function GroupBy<T,U>(
  response: Response,
  groupingKey:keyof T,
  outputAction: FuncGroupedOutTypes,
  omitKeys?:(keyof T)[],
  sortKey?: keyof T,
  sortOrder: /*'asc' | 'desc' = 'asc'*/SortOrder = 'asc',
  sum?:keyof T,
  maxSumKey?: keyof T,
  minSumKey?: keyof T): Promise<{[key:string]:U}> {
  const resp = await response.json()
  const resultBuff =  resp.reduce((acc:any,item:T) => {
    const newItem:U = typeof omitKeys == 'undefined' ? item as unknown as U  : omit(item,...omitKeys ) as unknown as U
   
    if(!acc[item[groupingKey]]){
      
      if(outputAction == 'Array'){
        acc[item[groupingKey]] = []
        acc[item[groupingKey]].push(newItem)
      }
      else if(outputAction == 'Object'){
        acc[item[groupingKey]] = newItem
      }
      return acc
    }
    else if(outputAction == 'Array'){
      acc[item[groupingKey]].push(newItem)
      return acc
    }
    return acc
  },{})
  //console.log(resultBuff)
  if(outputAction == 'Array'){
    //to do 
    //ej testat
    sortGroupedObject<T>(resultBuff,sortOrder,sortKey)
  }
  else if(outputAction == 'Number'){
    //to do
    
  }
  return resultBuff
  
}
function sortGroupedObject<T>(resultBuff:any,sortOrder:SortOrder,sortKey?:keyof T){
   Object.entries(resultBuff).forEach(([key,value]) => {
    if(sortKey){
      resultBuff[key].sort((a:T,b:T) => {
        if(sortOrder == 'asc'){
          return a[sortKey] > b[sortKey] ? 1 : -1;
        }
        else{
          return a[sortKey] < b[sortKey] ? 1 : -1;
        }
      })
    }
   })
}
export interface CompressedDepartmentReported extends Omit<DepartmentReportedResponse,'period'>   {
    kickbackReport?:boolean;
    statisticReport?:boolean;
    invoicesNotReported?:Invoice[];
}

type FuncGroupedOutTypes = 'Array' | 'Object' | 'Boolean' | 'String' | 'Number'
type FuncExtensions={
  'GroupBy' : <T,U>(response: Response, groupingKey:keyof T,outputAction:  FuncGroupedOutTypes,omitKeys?:(keyof T)[],sortKey?: keyof T,
  sortOrder?: /*'asc' | 'desc' = 'asc'*/SortOrder ,
  maxSumKey?: keyof T,
  minSumKey?: keyof T) => Promise<{[key:string]:U}> ,
  'ParseToObject': <T>(response:Response) => Promise<T>,
  'BuildDepartmentDictionary':<T>(response:Response) => Promise<T>
}

const ParseJsonResponse:FuncExtensions ={
  GroupBy,
  ParseToObject,
  BuildDepartmentDictionary,
  //TestGrouping
}


const DepartmentAction:{[key in DepartmentKeys] : (args:Readonly<DepartmentFunctionArgs>) => Promise<ReturnTypes<key>>} ={
  GetDepartment,
  GetDepartments,
  PostUpdateDepartments,
  DeleteDepartment,
  GetMultipleDepartmentsPerSupplier,
  GetUserAssignedDepartment,
  CheckAlreadyReportedDepartment
}
export{
  DepartmentAction
}

export const funcHandleAlreadyReported = async (reportType:ReportType,periods:string[],locale:string):Promise<{[key:string]:CompressedDepartmentReported}> => {
  const buffObj:DepartmentFunctionArgs = {reportCheck:{report:reportType,periods:periods,locale}}
  const res =  await CheckAlreadyReportedDepartment(buffObj)
  return res.Content.response
}


