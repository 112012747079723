import * as React from 'react'
import { InvoiceReport } from '../../services/API/InvoiceReports'
import Accordion from '../Accordion/StyledAccordion';
import i18n from '../../i18n';
import { ReadFriendlyNumber } from '../../lib/utils';
interface InfoProps{
    data:InvoiceReport;
    handleChange:(e:React.ChangeEvent<HTMLInputElement>,item?:InvoiceReport) => void;
}
const InfoBar = (props:InfoProps) => {
    const renderInfo = () => {
        const cellStyle : React.CSSProperties = {
          paddingLeft:8
        }   
         ///const { report,invoiceKey } = {...dataToEdit} as StandardInvoiceReport;
        return (
          <Accordion open title={i18n.t("invoiceReportList.invoiceReportModal.info").toString()} titleStyle={{fontSize: "1.1rem", margin: 0}} style={{padding: "0px 10px 0px"}} contentStyle={{paddingLeft: 4}}>
          <table 
          >
            <tbody>
              <tr>
                <td>{i18n.t("invoiceReportList.invoiceReportModal.comment")} </td>
                <td style={cellStyle}><input type="text" value={props.data.comment} onChange={(e) => 
                 props.handleChange(e,props.data)}/></td>
              </tr>
              <tr>
                <td>{i18n.t("invoiceReportList.invoiceReportModal.noOfInvoices")} </td>
                <td style={cellStyle}>{props.data.noOfInvoices}</td>
              </tr>
              <tr>
                <td>{i18n.t("invoiceReportList.invoiceReportModal.noOfInvoicesImported")} </td>
                <td style={cellStyle}>{props.data.noOfInvoicesImported}</td>
              </tr>
              <tr>
                <td>{i18n.t("invoiceReportList.invoiceReportModal.totalSumExclMoms")} </td>
                <td style={cellStyle}>{ReadFriendlyNumber(props.data.totalSumExclMoms ?? 0)}</td>
              </tr>
              <tr>
                <td>{i18n.t("invoiceReportList.invoiceReportModal.totalSumExclMomsImported")} </td>
                <td style={cellStyle}>{ReadFriendlyNumber(props.data.totalSumExclMomsImported ?? 0 )}</td>
              </tr>
              <tr>
                <td>{i18n.t("invoiceReportList.invoiceReportModal.noOfWarnings")} </td>
                <td style={cellStyle}>{props.data.noOfWarnings}</td>
              </tr>
             {/* {typeof this.state.department !== 'undefined' &&
             this.state.department.id !== null &&
              <tr>
                
              <td>
                {this.props.t('departmentList.singular')}
                <FontAwesomeIcon style={{marginLeft:'4px'}} icon='building'/>
              </td>
              
  
              <td style={cellStyle}>{this.state.department.department}</td>
            </tr>
             } */}
              
            </tbody>
          <p></p>
          </table>
          </Accordion>
        )
      }

      return(
        <>
        {renderInfo()}
        </>
      )
   
}
export{
    InfoBar
}