import { useTranslation } from "react-i18next";
import { API } from "./API";
import { Contract, ContractValidation } from "./API/Contracts";
import { Customer, CustomerGetOptions } from "./API/Customer";
import {
  InvoiceImportData,
  InvoiceFields,
  InvoiceKeys,
  FieldProperty,
  InvoiceReport,
  FieldStatus,
} from "./API/InvoiceReports";
import { t } from "i18next";
import { NewNullReport, NullReport } from "./API/NullReports";
import { TNews } from "./API/News";
import { NewUser, TUser, TUserValidation } from "./API/Users";
import {
  Code,
  FetchReturn,
  ImpersonationKEY,
  IsImpersonating,
  IsUserSinfra,
  ReadFriendlyNumber,
  RoundTo,
  StringToNumber,
  delayedAction,
  downloadFile,
  getActiveSupplier,
  getImpersonationUser,
  getSupplierOrgNr,
  parseJwt,
} from "../lib/utils";
//import { GetCategorys, ICategoryForPieChart, IDashboardLEV_SinfraContractArea } from "./API/DashBoard";
import { TSupplier } from "./API/Suppliers";
import { GroupedProjectNameAndEndDate, TKickback, TKickbackProjectReport, TKickbackReport, TKickbackReportById, TKickbackReportType } from "./API/Kickback";
import AuthService from "./AuthService";
import UserService from "./AuthService";
import { TGroup } from "./API/Groups";
import { IconName, faCheckCircle, faCircleCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";
import { KickbackChangeState, ProjectReport, ProjectReportPolished, StatusObj } from "../components/modal/SubmitKickbackProject";
import { CompressedDepartmentReported, Department, DepartmentAction, DepartmentDictionary, DepartmentFunctionArgs, DepartmentReportedResponse } from "./API/Departments";
import { useParams } from "react-router-dom";



const GetValidatedBillingDate = (
  date: string,
  shouldFormatValue?: boolean
): FieldProperty => {
  
  const current = new Date(date);
  const parsed = current.toLocaleDateString("sv-se");
  const temp = new Date();
  temp.setDate(1);
  temp.setMonth(temp.getMonth() + 1);
  const toDate = new Date(
    temp.getFullYear(),
    temp.getMonth(),
    temp.getDate(),
    0,
    0,
    0
  );
  temp.setMonth(temp.getMonth() - 2);
  const fromDate = new Date(
    temp.getFullYear(),
    temp.getMonth(),
    temp.getDate(),
    0,
    0,
    0
  );
  const val = parsed != "Invalid Date" && shouldFormatValue ? parsed : date;
  const isCorrectFormat =
    val.match(/^[0-9]{4}-[0-9]{2}-[0-9][0-9]?/) != null &&
    parsed != "Invalid Date" &&
    val === current.toLocaleDateString("sv-se");
  const outOfBounds =
    current.getTime() > toDate.getTime() ||
    current.getTime() < fromDate.getTime();
  const kickbackExists = _cachedKickbackPeriods.includes(current.toLocaleDateString("sv-SE").substring(0,7));
  var validation :{msg:string,status:FieldStatus } = { msg: "", status: "Good" };
  if(!isCorrectFormat) validation = { msg: t("invoiceReport.fields.date.invalidFormat"), status: "Error" }
  else if(kickbackExists ) validation = { msg: t("invoiceReport.fields.date.kickbackReported"), status: "Warning" }
  else if(outOfBounds) validation = { msg: t("invoiceReport.fields.date.intervalWarning"), status: "Warning" }
  return {
    key: "billingDate",
    value: parsed != "Invalid Date" && shouldFormatValue ? parsed : date,
    validation: validation.status,
    message: validation.msg,
    format: "YYYY-MM-DD",
    type: "Text",
    elevatedError: kickbackExists ? t("invoiceReport.elevatedDateErr").toString() : ""
  };
};

const GetValidatedContractId = (id: string): FieldProperty => {
  const options = _cachedContracts.map(
    (contract) => `${contract.contractId} - ${contract.contractName}`
  );
  const potentialContract = _cachedContracts.find(
    (cont) =>
      id == cont.contractId || id == `${cont.contractId} - ${cont.contractName}`
  );
  return {
    key: "contractId",
    value:
      potentialContract &&
      id ==
        `${potentialContract?.contractId} - ${potentialContract?.contractName}`
        ? potentialContract?.contractId
        : id,
    validation: potentialContract ? "Good" : "Error",
    message: t("invoiceReport.fields.contractId.notFound"),
    format: t("invoiceReport.fields.contractId.placeholder"),
    type: "Choice",
    options: options.sort(),
  };
};

const GetValidatedCustomer = (
  customerName: string,
  customerOrgId?: string
): FieldProperty => {
  const customers = _cachedCustomers;
  const options = customers.map((cust) => cust.kndNamn.trim());
  if (customerOrgId) {
    const customer = customers.find(
      (cust) => cust.kndOrgnr.toString() == customerOrgId
    );
    customerName = customer?.kndNamn.trim() ?? "";
  }
  return {
    key: "customer",
    value: customerName,
    validation: options.includes(customerName) ? "Good" : "Error",
    message: t("invoiceReport.fields.customer.notFound"),
    format: t("invoiceReport.fields.customer.placeholder"),
    type: "Choice",
    options: options,
  };
};

const GetValidatedCustomerOrgId = (
  customerOrgId: string,
  customerName?: string
): FieldProperty => {
  let formatted = customerOrgId.replace(/[^\d]/g, "");
  const customers = _cachedCustomers;
  const options = customers.map((cust) => cust.kndOrgnr.toString());
  if (customerName) {
    const customer = customers.find(
      (cust) => cust.kndNamn.trim() == customerName.trim()
    );
    formatted = customer?.kndOrgnr.toString() ?? "";
  }
  return {
    key: "customerOrgId",
    value: formatted,
    validation: options.includes(formatted) ? "Good" : "Error",
    message: t("invoiceReport.fields.customerOrgId.notFound"),
    format: t("invoiceReport.fields.customerOrgId.placeholder"),
    type: "Choice",
    options: options,
  };
};

const GetValidatedInvoiceNumber = (
  invoiceNumber: string,
  allCurrentInvoiceNumbers: string[]
): FieldProperty => {
  const empty = invoiceNumber.trim().length < 1;
  const tooLong = invoiceNumber.trim().length > 30;
  let isGood = !empty && !tooLong;
  let isWarning = allCurrentInvoiceNumbers.includes(invoiceNumber);
  return {
    key: "invoiceNumber",
    value: invoiceNumber,
    validation: isGood ? (isWarning ? "Warning" : "Good") : "Error",
    message: isWarning
      ? t("invoiceReport.fields.invoiceNumber.duplicate")
      : tooLong
      ? t("invoiceReport.fields.invoiceNumber.tooLong")
      : t("invoiceReport.fields.invoiceNumber.empty"),
    format: t("invoiceReport.fields.invoiceNumber.placeholder"),
    type: "Text",
  };
};

const GetValidatedNumber = (
  val: string,
  key:string
): FieldProperty => {
  val = val?.replaceAll(".", ",") ?? "";
  while (val[0] == "0" && val[1] != ",") val = val.slice(1);
  if (val[0] == ",") val = "0" + val;
  if (val.trim().length == 0) val = "0";
  return {
    key: key,
    value: val.toString(),
    validation:
      val.match(/^-?[0-9,\s]+$/) != null && val.split(",").length - 1 <= 1
        ? "Good"
        : "Error",
    message: t("invoiceReport.fields.sum.invalidFormat"),
    format: t("invoiceReport.fields.sum.placeholder"),
    type: "Text",
  };
};

const GetValidatedGeneric = (value: string, key: string) : FieldProperty => {
  return {
    key: key,
    value: value,
    validation: value.trim().length == 0 ? "Error" : "Good",
    message: t("invoiceReport.generic.message"),
    format: "Skriv...",
    type: "Text"
  };
}

const ValidateInvoice = (
  invoice: InvoiceImportData,
  allInvoices: InvoiceFields[]
): InvoiceFields => {
  const validatedInvoice : InvoiceFields = {
    id: invoice.id,
    groupId: invoice.groupId,
    billingDate: GetValidatedBillingDate(invoice.billingDate, true),
    contractId: GetValidatedContractId(invoice.contractId),
    customer: GetValidatedCustomer(invoice.customer),
    customerOrgId: GetValidatedCustomerOrgId(invoice.customerOrgId),
    invoiceNumber: GetValidatedInvoiceNumber(
      invoice.invoiceNumber,
      allInvoices.filter(inv => inv.groupId != invoice.groupId).map((invoice) => invoice.invoiceNumber.value)
    ),
    rowSumExclMoms: GetValidatedNumber(invoice.rowSumExclMoms, "sum"),
    articleQuantity: GetValidatedNumber(invoice.articleQuantity, "articleQuantity"),
    articlePricePerUnit: GetValidatedNumber(invoice.articlePricePerUnit, "articlePricePerUnit"),
    articleNumber: GetValidatedGeneric(invoice.articleNumber, "articleNumber"),
    articleText: GetValidatedGeneric(invoice.articleText, "articleText"),
    articleQuantityType: GetValidatedGeneric(invoice.articleQuantityType, "articleQuantityType")
  };

  if (validatedInvoice.customerOrgId.validation !== "Error") {
    validatedInvoice.customer = GetValidatedCustomer(
      invoice.customer,
      validatedInvoice.customerOrgId.value
    );
  }

  return validatedInvoice;
};

type InvoiceOrArray<T> = T extends InvoiceImportData[]
  ? InvoiceFields[]
  : InvoiceFields;
const ValidateInvoices = <T extends InvoiceImportData | InvoiceImportData[]>(
  invoices: T,
  allInvoices: InvoiceFields[]
): InvoiceOrArray<T> => {
  if (Array.isArray(invoices)) {
    return invoices.map((invoice) =>
      ValidateInvoice(invoice, allInvoices)
    ) as InvoiceOrArray<T>;
  } else {
    return ValidateInvoice(invoices, allInvoices) as InvoiceOrArray<T>;
  }
};

const CheckValidationStatuses = (
  invoices: InvoiceFields[],
  status: "Error" | "Good" | "Warning",
  reportingArticles: boolean
): boolean => {
  return invoices.some(
    (invoice) =>
      invoice.billingDate.validation === status ||
      invoice.contractId.validation === status ||
      invoice.customer.validation === status ||
      invoice.customerOrgId.validation === status ||
      invoice.invoiceNumber.validation === status ||
      invoice.rowSumExclMoms.validation === status ||
      reportingArticles && (invoice.articlePricePerUnit.validation === status ||
      invoice.articleQuantity.validation === status ||
      invoice.articleNumber.validation === status ||
      invoice.articleText.validation === status ||
      invoice.articleQuantityType.validation === status)
  );
};

const AreInvoicesValid = (invoices: InvoiceFields[], reportingArticles: boolean): boolean => {
  return !CheckValidationStatuses(invoices, "Error", reportingArticles);
};

const IsInvoiceValid = (invoice: InvoiceFields, reportingArticles: boolean): boolean => {
  return !CheckValidationStatuses([invoice], "Error", reportingArticles);
};

const DoesInvoiceHaveWarning = (invoice: InvoiceFields, reportingArticles: boolean): boolean => {
  return CheckValidationStatuses([invoice], "Warning", reportingArticles);
};

const DoesInvoiceHaveError = (invoice: InvoiceFields, reportingArticles: boolean): boolean => {
  return CheckValidationStatuses([invoice], "Error", reportingArticles);
};

const ValidateInvoiceProperty = (
  key: InvoiceKeys,
  newValue: string,
  invoice: InvoiceFields,
  allInvoices: InvoiceFields[]
): InvoiceFields => {
  const copy = { ...invoice };
  const validations = {
    billingDate: () => {
      copy.billingDate = GetValidatedBillingDate(newValue);
    },
    contractId: () => {
      copy.contractId = GetValidatedContractId(newValue);
    },
    customer: () => {
      copy.customer = GetValidatedCustomer(newValue);
      if (copy.customer.validation !== "Error") {
        copy.customerOrgId = GetValidatedCustomerOrgId(
          newValue,
          copy.customer.value
        );
      }
    },
    customerOrgId: () => {
      copy.customerOrgId = GetValidatedCustomerOrgId(newValue);
      if (copy.customerOrgId.validation !== "Error") {
        copy.customer = GetValidatedCustomer(
          newValue,
          copy.customerOrgId.value
        );
      }
    },
    invoiceNumber: () => {
      copy.invoiceNumber = GetValidatedInvoiceNumber(
        newValue,
        allInvoices.filter(inv => inv.groupId != invoice.groupId).map((invoice) => invoice.invoiceNumber.value)
      );
    },
    rowSumExclMoms: () => {
      copy.rowSumExclMoms = GetValidatedNumber(newValue, "rowSumExclMoms");
    },
    articleNumber: () => {
      copy.articleNumber = GetValidatedGeneric(newValue, "articleNumber");
    },
    articleText: () => {
      copy.articleText = GetValidatedGeneric(newValue, "articleText");
    },
    articleQuantity: () => {
      copy.articleQuantity = GetValidatedNumber(newValue, "articleQuantity");
    },
    articleQuantityType: () => {
      copy.articleQuantityType = GetValidatedGeneric(newValue, "articleQuantityType");
    },
    articlePricePerUnit: () => {
      copy.articlePricePerUnit = GetValidatedNumber(newValue, "articlePricePerUnit");
    }
  };
  validations[key]();
  return copy;
};


const getInvoiceReportStorageKey = () => {
  const invoiceReportStorageKey = "invoice_reports_save";
  if(!IsImpersonating())
    return `${AuthService.getUsername()}_${getSupplierOrgNr()}_${invoiceReportStorageKey}`;
  const item = window.sessionStorage.getItem(ImpersonationKEY);
  const jwt = JSON.parse(item as string);
  const accessToken = parseJwt(jwt.access_token);
  return `${accessToken.preferred_username}_${accessToken.groups.find(() => true)}_${invoiceReportStorageKey}`;
}

const InvoiceReports = {
  Post: (invoiceFields: InvoiceFields[], comment: string, reportingArticles: boolean,departmentId?:number) => {
  
    
    
    
      const statusObj =
      DataService.InvoiceReports.Validations.GetValidationStatus(invoiceFields, reportingArticles);
    const invoices : InvoiceImportData[] = invoiceFields.map((field) => ({
      id: field.id,
      groupId: field.groupId,
      billingDate: field.billingDate.value,
      contractId: field.contractId.value,
      customer: field.customer.value,
      customerOrgId: field.customerOrgId.value,
      invoiceNumber: field.invoiceNumber.value,
      rowSumExclMoms: field.rowSumExclMoms.value,
      articleQuantity: field.articleQuantity.value,
      articlePricePerUnit: field.articlePricePerUnit.value,
      articleNumber: field.articleNumber.value,
      articleText: field.articleText.value,
      articleQuantityType: field.articleQuantityType.value
    }));
    return API.InvoiceReports.Post(invoices, comment, statusObj.warnings,departmentId);
    
  
  },
  Get: async () => {
    const reports = await API.InvoiceReports.Get();
    return reports.map(report => {
      return {
        ...report,
        period: API.InvoiceReports.checkPeriod(report)
      }
    })
  },
  GetById: async (id:number) => {
    const report = await API.InvoiceReports.GetById(id);
    report.period = API.InvoiceReports.checkPeriod(report);
    return report;
  },
  RemoveById: (id:number) => {
    return API.InvoiceReports.Delete(id);
  },
  Validations: {
    ValidateInvoices,
    ValidateInvoiceProperty,
    AreInvoicesValid,
    CheckValidationStatuses,
    DoesInvoiceHaveWarning,
    DoesInvoiceHaveError,
    GetValidationStatus: (invoices: InvoiceFields[], reportingArticles: boolean) => {
      return invoices.reduce(
        (prev, curr, i, self) => {
          const isError = !IsInvoiceValid(curr, reportingArticles);
          const isWarning = DoesInvoiceHaveWarning(curr, reportingArticles);
          prev.errors += isError ? 1 : 0;
          prev.warnings += isWarning ? 1 : 0;
          return prev;
        },
        { warnings: 0, errors: 0 }
      );
    },
  },
  GetTemplates: () => {
    return API.InvoiceReports.GetTemplates();
  },
  DownloadTemplate: async (fileName: string) => {
    const fileRes = await API.InvoiceReports.GetTemplateByName(fileName);
    const fileData = await fileRes.blob();
    await downloadFile(fileName, fileData);
  },
  SaveToLocalstorage: (invoices: InvoiceFields[]) => {
    delayedAction('saveToLocalstorage', 1000,  () => {
      const savedValues = invoices.map(inv => {
        return [
          inv.customer.value, 
          inv.customerOrgId.value, 
          inv.contractId.value, 
          inv.invoiceNumber.value,
          inv.billingDate.value, 
          inv.rowSumExclMoms.value,
          inv.articleNumber.value,
          inv.articleText.value,
          inv.articlePricePerUnit.value,
          inv.articleQuantity.value,
          inv.articleQuantityType.value,
          inv.groupId
        ]
      });
      window.localStorage.setItem(getInvoiceReportStorageKey(), JSON.stringify(savedValues));
    })
  },
  LoadFromLocalstorage: () : InvoiceFields[] => {
    const potentialData = window.localStorage.getItem(getInvoiceReportStorageKey());
    if(!potentialData)
      return [];
    try {
      const data : any[] = JSON.parse(potentialData);
      const parsedData = data.map(dataArr => {
        const invoice : InvoiceImportData = {
          customer: dataArr[0],
          customerOrgId: dataArr[1],
          contractId: dataArr[2],
          invoiceNumber: dataArr[3],
          billingDate: dataArr[4],
          rowSumExclMoms: dataArr[5],
          articleNumber: dataArr[6],
          articleText: dataArr[7],
          articlePricePerUnit: dataArr[8],
          articleQuantity: dataArr[9],
          articleQuantityType: dataArr[10],
          groupId: dataArr[11],
          id: -1
        };
        return invoice;
      });
      return parsedData.reduce((prev, curr) => {
        return [...prev, DataService.InvoiceReports.Validations.ValidateInvoices(curr, prev)]
      }, [] as InvoiceFields[]);
    } 
    catch (error) {
      return [];
    };
  },
  GetStatus: (report: InvoiceReport) => {
    const reportStatus = AppSettings.invoiceReports.status.find(s => s.key == Number(report.status));
    if(!reportStatus)
      return {
        color: "",
        statusText: "",
        icon: findIconDefinition({ iconName: report.status as IconName, prefix: "fas"})
      }
    return {
      color: reportStatus.color,
      statusText: t("invoiceReportList.statusTexts."+report.status),
      icon: findIconDefinition({ iconName: reportStatus.icon as IconName, prefix: "fas"})
    }
  },
  CanActiveSupplierReport: async () => {
    const activeOrgNr = getActiveSupplier();
    const supplier = await Suppliers.GetByOrgNr(activeOrgNr);
    return !supplier.prevent_invoice_report || IsUserSinfra();
  },
  IsInvoiceImportInProgress : async () => {
    const res = await API.InvoiceReports.IsAnyInProgress();
    if(res.Code != Code.OK)
      return true;
    return res.Content.inProgress;
  },
  IsActiveSupplierTestingReport: async () => {
    const activeOrgNr = getActiveSupplier();
    const supplier = await Suppliers.GetByOrgNr(activeOrgNr);
    return supplier.testing_report == true;
  },
  ActiveSupplierEditRow:async () => {
    const activeOrgNr = getActiveSupplier();
    const supplier = await Suppliers.GetByOrgNr(activeOrgNr);
    return supplier.rowEdit == true;
  }
};

let _cachedCustomers: Customer[] = [];

const Customers = {
  Init: async (opt?:CustomerGetOptions) => {
    const customers = await API.Customers.Get(opt);
    
    const currentSupplier = getSupplierOrgNr();
    _cachedCustomers = customers.filter(customer => customer.kndOrgnr.toString() != currentSupplier);
  },
  Get: async (opt?:CustomerGetOptions) => {
    if (_cachedCustomers.length < 1) return API.Customers.Get(opt);
    return _cachedCustomers;
  },
};

let _cachedContracts: Contract[] = [];
const Contracts = {
  Init: async (onlyActive?: boolean) => {
    const contracts = await API.Contracts.Get({ onlyactive: onlyActive });
    _cachedContracts = contracts;
  },
  Get: async () => {
    return API.Contracts.Get();
  },
  GetActive: async () => {
    return API.Contracts.Get({ onlyactive: true });
  },
  GetBySupplierOrgNr: async (supplierOrgNr: string) => {
    return API.Contracts.Get({supplierOrgId: supplierOrgNr, onlyactive: true});
  },
  GetNextContractId: async () => {
    const contracts = await API.Contracts.Get();
    contracts.sort((c1, c2) => {
      const id1 = parseInt(c1.contractId);
      const id2 = parseInt(c2.contractId);
      if (isNaN(id1) && isNaN(id2)) return 0;
      if (isNaN(id1)) return 1;
      if (isNaN(id2)) return -1;
      return id1 < id2 ? 1 : -1;
    });
    const highestContractId =
      contracts.length > 0 ? parseInt(contracts[0].contractId) : 1;
    return isNaN(highestContractId) ? 1 : highestContractId + 1;
  },
  GetById: (id: number) => API.Contracts.GetById(id),
  ValidateContract: async (contract: Contract) => {
    const [contracts, suppliers, categories] = await Promise.all([
      API.Contracts.Get(),
      API.Suppliers.Get(),
      API.ContractCategories.Get(),
    ]);
    const validationObj: ContractValidation = {
      contractId: "",
      kickbackProcent: "",
      contractTypeId: "",
      supplierName: "",
      contractName: "",
      procurementValue: ""
    };
    if (contract.contractName.trim().length == 0) {
      validationObj.contractName = t("contractEditPage.contractName.empty");
    }
    if (!contract.archived &&
      contracts.find(
        (c) =>
          c.contractId.toLocaleLowerCase() ==
            contract.contractId.toLocaleLowerCase() &&
          !c.archived  &&
          contract.id != c.id
      ) != undefined
    ) {
      validationObj.contractId = t("contractEditPage.contractId.duplicate");
     
    }
    if (categories.find((c) => c.id == contract.contractTypeId) == undefined) {
      validationObj.contractTypeId = t("contractEditPage.contractType.noMatch");
    }
    if (parseFloat(contract.kickbackProcent) < 0) {
      validationObj.kickbackProcent = t(
        "contractEditPage.kickbackProcent.negative"
      );
    }
    const setSupplier = suppliers.find((s) => s.supplierOrgNr == contract.supplierOrgNr);
    if (contract.detached == false && setSupplier == undefined) {
      validationObj.supplierName = t("contractEditPage.supplierOrgNr.noMatch");
    }
    if(contract.detached == true && contract.supplierName.trim().length < 1){
      validationObj.supplierName = t("contractEditPage.supplierOrgNr.empty")
    }
    return validationObj;
  },
  GetContractCategories: () => API.ContractCategories.Get(),
  Post: (contract: Contract) => {
    if (contract.id == -1) {
      return API.Contracts.Post(contract);
    }
    return API.Contracts.Put(contract);
  },
  // GetMonthlySum: async (month: string, year: string) : Promise<Required<Contract>[]> => {
  //   const [ contracts, invoices ] = await Promise.all([
  //     API.Contracts.Get(),
  //     API.Invoices.Get({month, year})
  //   ])
  //   return contracts.map(contract => {
  //     const sum = invoices
  //       .filter((invoice) => invoice.contractId == contract.contractId)
  //       .reduce((prev: number, curr : Invoice, i :number, self: Invoice[]) => {
  //         return prev + Number(curr.priceExcMoms);
  //       }, 0)
  //     return {
  //       ...contract,
  //       monthlySum: sum.toString()
  //     }
  //   })
  // }
};

const NullReports = {
  Get: async () => { return API.NullReports.Get()},
  Post: async (nullReport: NewNullReport) => {
    return API.NullReports.Post(nullReport);
  },
  IsPeriodReported: async (period: string) => {
    const year = period.split("-")[0];
    const month = period.split("-")[1];
    const [contracts, nullReports] = await Promise.all([
      API.Invoices.GetMonthly({ year, month }),
      API.NullReports.Get({ year, month }),
    ]);
    return nullReports.some((n) => n.period == period) || contracts.length > 0;
  },
  UpdateStatus:async(nullReport:NullReport) =>{
    return await API.NullReports.UpdateStatus(nullReport);
  }
};

const News = {
  Post: (news: TNews[]) => {
    if (news[0].id == -1) {
      return API.News.Post(news);
    }
    return API.News.Put(news);
  },
  Get: () => {
    return API.News.Get();
  },
  GetById: (id: string) => {
    return API.News.GetById(id);
  },
  isPublished: (news: TNews) => {
    var today = new Date();
    return news.publishedFrom <= today && news.publishedUntil > today;
  },
  Remove: (id: string) => {
    return API.News.Delete(id);
  },

};

const Invoices = {
  Get: async (opt?:any) =>{
    return await API.Invoices.Get(opt);
  },
  GetMonthly: () => {
    return API.Invoices.GetMonthly();
  },
  GetSupplierInvoiceByPeriod: (
    supplierOrgId: string,
    year: string,
    month: string
  ) => {
    return API.Invoices.GetMonthly({
      month: month,
      year: year,
      supplier: supplierOrgId,
    });
  },
  GetMissedPeriods: () => API.Invoices.GetMissedPeriods(),
  GetProjectReportData: async (period:string,lang:string) => {return await API.Invoices.GetProjectReportData(period,lang)}
};

const Users = {
  Get: async () => {
    return API.Users.Get();
  },
  GetById: async (id: string): Promise<Required<TUser>> => {
    const [user, groups, roles] = await Promise.all([
      API.Users.GetById(id),
      API.Groups.GetUserGroups(id),
      API.Roles.GetUserRoles(id),
    ]);
    let suppliers: TSupplier[] = [];
    for (let i = 0; i < groups.length; i++) {
      const grp = groups[i];
      if (grp.name != AppSettings.sinfraBaseGroupName) {
        const supplier = await API.Suppliers.GetByOrgNr(grp.name);
        suppliers.push({
          ...supplier,
          keycloakId: grp.id,
        });
      }
    }
    return {
      ...user,
      SupplierGroups: suppliers,
      roles: roles,
      isSinfraAccount:
        groups.find((grp) => grp.id == AppSettings.sinfraBaseGroupId) !=
        undefined,
    };
  },
  ChangeUserStatus: (userId: string, enabled: boolean) => {
    return API.Users.Put(userId, { enabled: enabled });
  },
  ResetPassword: (userId: string) => {
    return API.Users.SendActionEmail(userId, ["UPDATE_PASSWORD"]);
  },
  CreateUser: async (newUser: TUser) => {
    const userSupplierGroups = newUser.SupplierGroups ?? [];
    const ensureRequests: Promise<TSupplier>[] = userSupplierGroups.map(
      (grp) => {
        return Groups.Ensure(grp.supplierOrgNr)
          .then((kcGroup) => {
            return {
              ...grp,
              keycloakId: kcGroup.Content.id,
            } as TSupplier;
          })
          .catch((err) => {
            return {} as TSupplier;
          });
      }
    );
    const supplierGroups = await Promise.all(ensureRequests);
    newUser.SupplierGroups = supplierGroups;
    const result = await API.Users.Post(newUser);
    if (result.Code != Code.OK) return result;
    const user = await API.Users.GetByEmail(newUser.email);
    const userRoles = newUser.roles ?? [];
    const roleRes = await API.Roles.AddUserRoles(user.id, userRoles);
    if (roleRes.Code != Code.OK) return roleRes;
    const mailRes = await API.Users.SendActionEmail(user.id, ["VERIFY_EMAIL"]);
    if (mailRes.Code != Code.OK) return mailRes;
    return result;
  },
  UpdateUser: async (userBeforeEdit: TUser, user: TUser) => {
    const respUser = await API.Users.Put(userBeforeEdit.id, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      attributes:{...user.attributes}
    });
    
    if (respUser.Code != Code.OK) return respUser;

    //ROLES
    const userRoles = user.roles ?? [];
    const rolesToRemove =
      userBeforeEdit.roles?.filter(
        (role) => !userRoles.find((_role) => role.id == _role.id)
      ) ?? [];
    const remRoleRes = await API.Roles.RemoveUserRoles(
      userBeforeEdit.id,
      rolesToRemove ?? []
    );
    if (remRoleRes.Code != Code.OK) return remRoleRes;
    const rolesToAdd =
      user.roles?.filter(
        (role) => !userBeforeEdit.roles?.find((_role) => role.id == _role.id)
      ) ?? [];
    const addRoleRes = await API.Roles.AddUserRoles(
      userBeforeEdit.id,
      rolesToAdd ?? []
    );
    if (addRoleRes.Code != Code.OK) return addRoleRes;

    //GROUPS
    const groups = user.isSinfraAccount ? [] : user.SupplierGroups ?? [];
    const groupsBeforeEdit = userBeforeEdit.SupplierGroups ?? [];
    const groupsToRemove = groupsBeforeEdit.filter(
      (grp) => !groups.find((_g) => _g.supplierOrgNr == grp.supplierOrgNr)
    );
    for (let i = 0; i < groupsToRemove.length; i++) {
      const group = groupsToRemove[i];
      if (group.keycloakId) {
        const remGrpResp = await API.Groups.RemoveUserFromGroup(
          userBeforeEdit.id,
          group.keycloakId
        );
        if (remGrpResp.Code != Code.OK) return remGrpResp;
      }
    }
    const groupsToAdd = groups.filter(
      (grp) =>
        !groupsBeforeEdit.find((_g) => _g.supplierOrgNr == grp.supplierOrgNr)
    );
    for (let i = 0; i < groupsToAdd.length; i++) {
      const group = groupsToAdd[i];
      const kcGroup = await Groups.Ensure(group.supplierOrgNr);
      if (kcGroup.Code != Code.OK) return kcGroup;
      const addGrpResp = await API.Groups.AddUserToGroup(
        userBeforeEdit.id,
        kcGroup.Content.id
      );
      if (addGrpResp.Code != Code.OK) return addGrpResp;
    }

    //SINFRA-USER
    const sinfraGroupRequest = user.isSinfraAccount
      ? API.Groups.AddUserToGroup(
          userBeforeEdit.id,
          AppSettings.sinfraBaseGroupId
        )
      : API.Groups.RemoveUserFromGroup(
          userBeforeEdit.id,
          AppSettings.sinfraBaseGroupId
        );
    const sinfraGroupRes = await sinfraGroupRequest;
    if (sinfraGroupRes.Code != Code.OK) return sinfraGroupRes;

    return respUser;
  },
  ValidateUser: (user: Partial<TUser>): TUserValidation => {
    let validations: TUserValidation = {
      email: "",
      firstName: "",
      lastName: "",
      SupplierGroups: "",
    };
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        user.email as string
      ) == false
    ) {
      validations.email = t("api.users.validateInvalidEmail");
    }
    if (!user.email?.trim().length) {
      validations.email = t("api.users.validateEmptyEmail");
    }
    if (!user.isSinfraAccount) {
      if (!user.SupplierGroups?.length) {
        validations.SupplierGroups = t("api.users.validateInvalidSupplier");
      }
    }

    return validations;
  },
  Impersonate: (userId: string) => {
    return API.Users.Impersonate(userId);
  },
  UpdateCurrentUserLang: (locale: string) => {
    const user = AuthService.getUser();
    const userId = user?.sub ?? "";
    return API.Users.Put(userId, { attributes: { locale: locale } });
  }
};

const Groups = {
  Get: () => {
    return API.Groups.Get({ onlySuppliers: false });
  },
  GetSuppliers: () => {
    return API.Groups.Get({ onlySuppliers: true });
  },
  Ensure: async (orgNr: string) => {
    let getGrpRes = await API.Groups.GetByName(orgNr);
    if (getGrpRes.Code == Code.NOT_FOUND) {
      const addGrpRes = await API.Groups.Add(orgNr);
      if (addGrpRes.Code != Code.OK) return addGrpRes;
      getGrpRes = await API.Groups.GetByName(orgNr);
      if (getGrpRes.Code != Code.OK) return getGrpRes;
    }
    return getGrpRes;
  },
};

const Roles = {
  Get: () => {
    return API.Roles.Get();
  },
};

const Suppliers = {
  Get: async (active?:boolean) => {
    const kcSupplierGroups = await API.Groups.Get({ onlySuppliers: true });
    const dbSuppliers = await API.Suppliers.Get(active);
    
    return dbSuppliers.map((supplier) => {
      const matchingGroup = kcSupplierGroups.find(
        (grp) => grp.name == supplier.supplierOrgNr
      );
      if (matchingGroup) supplier.keycloakId = matchingGroup.id;
      return supplier;
    });
  },
  GetSupplierUsers: async (orgnr: string): Promise<TUser[]> => {
    
    const potentialGroupRes = await API.Groups.GetByName(orgnr);
   
    if (potentialGroupRes.Code != Code.OK) {
      return [];
    }
    const membersRes = await API.Groups.GetGroupMembers(
      potentialGroupRes.Content.id
    );
    return membersRes.Content.members;
  },
  GetByOrgNr: async (orgNr: string) => {
    const [supplierRes, potentialGroupRes] = await Promise.all([
      API.Suppliers.GetByOrgNr(orgNr),
      API.Groups.GetByName(orgNr)
    ]);
    const supp : TSupplier = {
      ...supplierRes,
      keycloakId: potentialGroupRes.Content.id,
      divided_reporting: potentialGroupRes.Content.attributes?.divided_reporting?.find(g => g == "true") != undefined ?? false,
      prevent_invoice_report: potentialGroupRes.Content.attributes?.prevent_invoice_report?.find(g => g == "true") != undefined ?? false,
      prevent_kickback_report: potentialGroupRes.Content.attributes?.prevent_kickback_report?.find(g => g == "true") != undefined ?? false,
      project_report: potentialGroupRes.Content.attributes?.project_report?.find(g => g == "true") != undefined ?? false,
      testing_report: potentialGroupRes.Content.attributes?.testing_report?.find(g => g == "true") != undefined ?? false,
      rowEdit:potentialGroupRes.Content.attributes?.rowEdit?.find(g => g == "true") != undefined ?? false
    }
    return supp;
  },
  UpdateSupplierGroup: async (supplier : TSupplier, members: TUser[],departments:Department[]) => {
   //update departments on supplier 
   const updatedOrAddedDepts = departments.filter(x => x.changed == true) 
    let mappedMembers:TUser[] = [...members]
    if(updatedOrAddedDepts.length > 0){
      const mapped:DepartmentFunctionArgs = {}
      mapped.departments = [...updatedOrAddedDepts]
      mapped.departments.forEach(x => x.supplierOrgId = supplier?.supplierOrgNr)
      console.log({MAPPADISUPPLIERGROUP:mapped})
      const newIdsResponse =  await DataService.Departments.PostNewDepartments(mapped)
      if(newIdsResponse.Code !== Code.OK){
          return newIdsResponse
      }
      mappedMembers =  members.map(x => {
       x.attributes.departments = x.attributes.departments.map(q => {
          if(typeof newIdsResponse.Content.response[`${q}`] !== 'undefined'){
            return newIdsResponse.Content.response[`${q}`]
        }  
        else{
          return q
        }
        })
        return x
      })
    }
   
   
    //update keycloak group + users who are members of the group
    const groupRes = await Groups.Ensure(supplier.supplierOrgNr);
    if(groupRes.Code != Code.OK)
      return groupRes;
    const group : TGroup = {
      id: groupRes.Content.id,
      name: groupRes.Content.name,
      attributes: {
        divided_reporting: [supplier.divided_reporting?.toString() ?? "false"],
        prevent_invoice_report: [supplier.prevent_invoice_report?.toString() ?? "false"],
        prevent_kickback_report: [supplier.prevent_kickback_report?.toString() ?? "false"],
        project_report: [supplier.project_report?.toString() ?? "false"],
        testing_report: [supplier.testing_report?.toString() ?? "false"],
        rowEdit:[supplier.rowEdit?.toString() ?? "false"]
      }
    }
    const updateGroupRes = await API.Groups.Update(group);
    if(updateGroupRes.Code != Code.OK)
      return updateGroupRes;

    const contracts =  await API.Contracts.Get({supplierOrgId: supplier.supplierOrgNr, onlyactive: true});

    const currentMembersRes = await API.Groups.GetGroupMembers(group.id);
    if(currentMembersRes.Code != Code.OK)
      return currentMembersRes;

    const modifiedMembers = mappedMembers.map(member => {
      if(supplier.divided_reporting != true)
      {
        //Remove the contractIds related to supplier that is getting divided_reporting turned off
        member.attributes.reporting_contracts = member.attributes.reporting_contracts.filter(id => !contracts.find(c => c.contractId == id));
      }
      return member;
    })
    const currentMembers = currentMembersRes.Content.members;
    const stillMembers = modifiedMembers.filter(cm => modifiedMembers.find(m => m.id == cm.id) && cm.id != "");
    const removeMembers = currentMembers.filter(cm => modifiedMembers.find(m => m.id == cm.id) == undefined);
    const newMembers = modifiedMembers.filter(m => currentMembers.find(cm => cm.id == m.id) == undefined);

    const userRemovals = removeMembers.map(async member => {
      member.attributes.reporting_contracts = member.attributes.reporting_contracts.filter(id => !contracts.find(c => c.contractId == id));
      await API.Users.Put(member.id, { attributes: member.attributes }).then(res => res);
      return API.Groups.RemoveUserFromGroup(member.id, group.id).then(res => res);
    });

    const userAdditions = newMembers.map(async member => {
      if(member.id == ""){
        const userRes = await API.Users.Post({...member, SupplierGroups: [{supplierOrgNr:group.name, supplierName:""}]});
        if(userRes.Code != Code.OK) return userRes;
        const getUserRes = await API.Users.GetByEmail(member.email);
        await API.Users.SendActionEmail(getUserRes.id, ["VERIFY_EMAIL"]);
        return userRes;
      }
      return API.Groups.AddUserToGroup(member.id, group.id).then(res => res);
    });

    const userPromises = stillMembers.map(async user => {
      return API.Users.Put(user.id, { attributes: user.attributes }).then(res => res);
    })
    const userResp = await Promise.all([...userAdditions, ...userPromises, ...userRemovals]);
    if(userResp.some(res => res.Code != Code.OK))
      return userResp.find(res => res.Code != Code.OK)
    return updateGroupRes;
  }
};

let _cachedKickbackPeriods : string[] =  [];
const Kickback = {
  GetProjectReports: async(compressed:boolean,reportId?:string):Promise<TKickbackProjectReport[]> => {
    const res = await API.Kickback.GetKickbackProjectReports(compressed,reportId)
    return res
  },
  GetProjectReportById: async (reportId:string) => {
    const [report, contracts] = await Promise.all([
      API.Kickback.GetProjectReportById(reportId),
      API.Contracts.Get()
    ]);
   console.log('HEJ',report.kickbackChanges)
    report.contracts = report.contracts.map(contract => {
      const contractData = contracts.find(c => c.contractId == contract.contractId);
      return {
        ...contract,
        contractId: contractData?.contractId ?? "",
        invoicedSum: RoundTo(contract.invoicedSum, 3),
        reportedSum: RoundTo(contract.reportedSum, 3),
        name: contractData?.contractName ?? "",
        type: contractData?.contractType ?? "",
        kickbackProcent: StringToNumber(contractData?.kickbackProcent ?? "0")
      }
    });
    return report;
  },
  GetTypeaheadListsForKickbackProjectReports: async ():Promise<[string[],string[],string[],GroupedProjectNameAndEndDate,any[],any[]]> => {
    const res = await API.Kickback.GetTypeaheadListsForKickbackProjectReports();
    return res
  },
  PostProjectReport: async(reports:ProjectReportPolished[],invoiceReport:InvoiceFields[],statusObject:StatusObj,lang:string,departmentId?:number,kickbackChanged?:KickbackChangeState): Promise<any> => {
    const mapped:any[] = invoiceReport.map(x => {
      const inv:any = {
        
        customer: x.customer.value,
        customerOrgId: x.customerOrgId.value,
        contractId: x.contractId.value,
        invoiceNumber: x.invoiceNumber.value,
        billingDate: x.billingDate.value,
       // sumExclMoms: x.rowSumExclMoms.value,
        articleNumber:x.articleNumber.value,
        articlePricePerUnit:x.articlePricePerUnit.value,
        articleQuantity:x.articleQuantity.value,
        articleQuantityType: x.articleQuantityType.value,
        articleText: x.articleText.value,
        rowSumExclMoms: x.rowSumExclMoms.value

      } 
      return inv
    })
    const res = await API.Kickback.PostProjectReport(reports,mapped,statusObject,lang,departmentId,kickbackChanged)
    return res
  },
  SubmitReport: async (period: string/*, comment: string,*/, kickbacks: TKickback[],departmentId?:number) => {
    const reportRes = await API.Kickback.Post({
      period,
      //comment,
      kickbackData: kickbacks.map(kb => ({ contractId: kb.contractId, sum: kb.sumExclMomsChangesProps.value,comment:kb.comment })),
      departmentId
    });
    return reportRes;
  },
  GetNewKickbackItems: async (year: string, month: string) => {
    const [contracts, invoiceData] = await Promise.all([
      API.Contracts.Get({ onlyactive: true }),
      API.Invoices.GetInvoicesToKickback({
        month,
        year
      })
    ]);
    const kickbacks = contracts
      .filter(c => invoiceData.find(inv => inv.contractId == c.contractId)) //only show contracts with reported statistics
      .map((cont) => {
        const contractData = invoiceData.filter(
          (inv) => inv.contractId == cont.contractId
        );
        const contractSum = contractData.reduce((prev, curr) => {
          return prev + curr.priceExcMoms;
        }, 0);
        const kickback: TKickback = {
          comment: "",
          contractId: cont.contractId,
          kickbackProcent: StringToNumber(cont.kickbackProcent),
          sumExclMoms: contractSum,
          sumExclMomsChangesProps: GetValidatedNumber(ReadFriendlyNumber(contractSum), "sum"),
          contractName: cont.contractName,
          contractType: cont.contractType,
        };
        return kickback;
    });
    return kickbacks;
  },
  GetByReportId: async (reportId: string): Promise<Required<TKickbackReportById>> => {
    const [kickbackReport, contracts] = await Promise.all([
      API.Kickback.GetKickbacksByReportId(reportId),
      API.Contracts.Get(),
    ]);
    const contractsReported = kickbackReport.contracts.reduce((prev, curr, i, self) => {
      if(prev[curr.contractId]){
        prev[curr.contractId].reportedSum += curr.reportedSum;
      }
      else {
        prev[curr.contractId] = {
          contractId: curr.contractId,
          invoicedSum: 0,
          reportedSum: curr.reportedSum,
          comment:curr.comment
        };
      }
      return prev;
    }, {} as {[key:string]:any})
    
    const groupedByContracts = kickbackReport.invoices.reduce((prev, curr, i, self) => {
      if(prev[curr.contractId]){
        prev[curr.contractId].invoicedSum += curr.priceExcMoms;
      }
      else {
        prev[curr.contractId] = {
          contractId: curr.contractId,
          invoicedSum: curr.priceExcMoms,
          reportedSum: 0
        };
      }
      return prev;
    }, contractsReported);
    
    const kickbackContracts = Object.keys(groupedByContracts).map(key => {
      const contract = groupedByContracts[key];
      const contractInfo = contracts.find(c => c.contractId == contract.contractId);
      return {
        contractId: contractInfo?.contractId ?? "",
        invoicedSum: RoundTo(contract.invoicedSum, 3),
        reportedSum: RoundTo(contract.reportedSum, 3),
        name: contractInfo?.contractName ?? "",
        type: contractInfo?.contractType ?? "",
        kickbackProcent: StringToNumber(contractInfo?.kickbackProcent ?? "0"),
        comment: contract.comment
      }
    })
    
    return {
      ...kickbackReport,
      contracts: kickbackContracts
    }
  },
  GetReports: async (compressed:boolean,reportId?:string) => {
    const [kickbackReports, projectKickbackReports] = await Promise.all([
      API.Kickback.GetReports(),
      API.Kickback.GetKickbackProjectReports(compressed,reportId)
    ]);
  if(!compressed && reportId == undefined){
    const groupedByReportId = projectKickbackReports.reduce((prev, curr, ind, self) => {
      if(prev[curr.reportId]){
        prev[curr.reportId] = prev[curr.reportId].concat(curr);
      }
      else{
        prev[curr.reportId] = [curr];
      }
      return prev;
    }, {} as {[reportId:string]:TKickbackProjectReport[]});
    const reportIds = Object.keys(groupedByReportId);
    const projectReportsAsKickbackReports = reportIds.map(reportId => {
      const reportData = groupedByReportId[reportId];
      const sample = reportData.find(r => r);
      if(!sample)
        return undefined;
      let foundContracts : number[] = [];
      const kickbackReport = reportData.reduce((prev, curr, ind, self) => {
        const contracts = foundContracts.indexOf(curr.contractId) >= -1 ? foundContracts : foundContracts.concat(curr.contractId)
        return {
          ...prev,
          totalKickback: prev.totalKickback + (curr.kickbackSum ?? 0),
          totalSum: prev.totalSum + (curr.sumExclMoms ?? 0) ,
          noOfContracts : contracts.length
        }
        }, {
          period: sample.period.toString(),
          reporter:sample.reporter,
          status:sample.status,
          totalKickback: 0 ,
          supplierOrgId:sample.supplierOrgId,
          totalSum: 0,
          reportDate:sample.reportDate,
          reportId:sample.reportId.toString(),
          noOfContracts:0,
          type:'Project'
          } as TKickbackReport);
      return kickbackReport;
    })
    .filter((report): report is TKickbackReport => report != undefined);

    return kickbackReports.concat(projectReportsAsKickbackReports);
  }
  else{
    return kickbackReports.concat(projectKickbackReports as any)
  }
    
  },
  GetReportedPeriods: async () => {
    if(IsUserSinfra())
      return [];
    const response = await API.Kickback.GetReports({ select: "period" });
  
    const periods = response.map(k => k.period);
    _cachedKickbackPeriods = periods;
    return _cachedKickbackPeriods;
  },
  GetPossibleKickbackPeriods: async () => {
    const invoices = await API.Invoices.GetInvoicesToKickback({select: "billingDate"});
    const possiblePeriods = invoices.map(inv => inv.billingDate.toLocaleDateString("sv-se").substring(0,7))
      .filter((val, i, self) => self.indexOf(val) >= i);
    return possiblePeriods;
  },
  ValidateSum: (sum: string) => {
    return GetValidatedNumber(sum, "sum");
  },
  SubmitAsFile: (file: File, period: string,comment:string) =>
    API.Kickback.PostFile(file, period,comment),
  DownloadFile: async (fileName: string, supplierOrgNr: string) => {
    const file = await API.Kickback.GetFile(fileName, supplierOrgNr);
    const fileData = await file.blob();
    await downloadFile(fileName, fileData);
  },
  DeleteFile: async (fileName:string,supplierOrgId:string) => {
    
    const response = await API.Kickback.DeleteFile(fileName,supplierOrgId)
    return response
  }, 
  UpdateFileStatus: async (fileName:string,supplierOrgId:string,status:number) => {
    const response = await API.Kickback.UpdateFileStatus(fileName,supplierOrgId,status);
    return response;
  },
  IsFileReport: (kickback: TKickbackReport) => kickback.fileName,
  GetMissedPeriods: () => API.Kickback.GetMissedPeriods(),
  IsPeriodReported: async (period: string, reportingContracts: string[]) => {
    const year = period.split("-")[0];
    const month = period.split("-")[1];
    const user = IsImpersonating() ? getImpersonationUser() : AuthService.getUser();
    const supplier = await DataService.Suppliers.GetByOrgNr(getActiveSupplier());
    if(supplier.divided_reporting){
      const kickbacks = await API.Kickback.Get({month, year});
      const usersReportingContracts : number[] = user.reporting_contracts ?? [];
      const contractsToReport = usersReportingContracts.filter(id => reportingContracts.indexOf(id.toString()) >= 0);
      return contractsToReport.every((contractId:number) => kickbacks.find(k => k.contractId == contractId.toString()) != undefined)
    }
    const periodKickback = await API.Kickback.GetReports({ year, month });
    return periodKickback.some((k) => k.period == period);
  },
  GetKickbackProjectReports: async (compressed:boolean,reportId?:string) =>{
    return await API.Kickback.GetKickbackProjectReports(compressed,reportId)
  },
  CanActiveSupplierReport: async () => {
    const activeOrgNr = getActiveSupplier();
    const supplier = await Suppliers.GetByOrgNr(activeOrgNr);
    return !supplier.prevent_kickback_report || IsUserSinfra();
  }
};

const Dashboard = {
  GetCategorys: async () => {
    return await API.Dashboard.GetCategorys();
  },
  GetExpectedReports: async () => {
    return await API.Dashboard.GetExpectedReports();
  },
  // GetLEV_SupplierMissingPeriods: async () => {
  //   return await API.Dashboard.GetLEV_SupplierMissingPeriods();
  // },
  // GetLEV_SuppliersReceivedReports: async () => {
  //   return await API.Dashboard.GetLEVSuppliersReceivedReports();
  // },
  // GetLEV_AllImportedInvoices: async () => {
  //   return await API.Dashboard.GetLEV_AllImportedInvoices();
  // },
  // GetLEV_MonthlyContractSummary: async () => {
  //   return await API.Dashboard.GetLEV_MonthlyContractSummary();
  // },
  // GetLEV_MonthlyPerContract: async () => {
  //   return await API.Dashboard.GetLEV_MonthlyPerContract();
  // },
  // GetLEV_MonthlyPerCustomer: async () => {
  //   return await API.Dashboard.GetLEV_MonthlyPerCustomer();
  // },
  // GetLEV_MonthlyPerSupplier: async () => {
  //   return await API.Dashboard.GetLEV_MonthlyPerSupplier();
  // },
  // GetLEV_SinfraContractArea: async () => {
  //   return await API.Dashboard.GetLEV_SinfraContractArea();
  // },
  // GetLEV_SuppliersSummary2022: async () => {
  //   return await API.Dashboard.GetLEV_SuppliersSummary2022();
  // },
  // GetLEV_SuppliersSummary2023: async () => {
  //   return await API.Dashboard.GetLEV_SuppliersSummary2023();
  // },
  // GetLEV_SuppliersSummary2024: async () =>{
  //   return await API.Dashboard.GetLEV_SuppliersSummary2024();
  // },
  // GetLEV_ExpectedReports: async () => {
  //   return await API.Dashboard.GetLEV_ExpectedReports();
  // },
  GetData_fordashboardCard: async () => {
    return await API.Dashboard.GetData_fordashboardCard();
  },
  GetData_Invoicesfordashboard:async (contractdIds:string[],period:string) => {
    return await API.Dashboard.GetData_Invoicesfordashboard(contractdIds,period);
  },
  GetData_ICategoryForPieChart:async (period:string) =>{
    return await API.Dashboard.GetData_ICategoryForPieChart(period);
  },
  GetData_IInvoiceDataForPieChart:async () =>{
    return await API.Dashboard.GetData_IInvoiceDataForPieChart();
  },
  // GetIDashboardLEV_Systemlog:async () =>{
  //   return await API.Dashboard.GetIDashboardLEV_Systemlog()
  // },
  GetListOfAvailableLEV_views:async () =>{
    return await API.Dashboard.GetListOfAvailableLEV_views();
  },
  Get_LEV_Views:async (view:string) =>{
    return await API.Dashboard.Get_LEV_Views(view);
  }
}
const Logs = {
  GetLogsByBatchId:async (batchId:string) =>{
    return await API.Logs.GetLogsByBatchId(batchId);
  }
}
const Departments = {
  GetNewDepartment: () : Department => {
    return {
      id: 0,
      department: "",
      changed:false
    }
  },
  Get: async (supplierOrgId:DepartmentFunctionArgs) : Promise<Department[]> => {
    return await DepartmentAction.GetDepartments(supplierOrgId);
  },
  GetDepartment:async(id:DepartmentFunctionArgs):Promise<Department|undefined> => {
    return await DepartmentAction.GetDepartment(id)
  },
  PostNewDepartments:async (departments:DepartmentFunctionArgs) =>{ 
   return await DepartmentAction.PostUpdateDepartments(departments);
  },
  GetMultipleDepartments: async (supplierOrgIds:DepartmentFunctionArgs):Promise<any> => {
    return await DepartmentAction.GetMultipleDepartmentsPerSupplier(supplierOrgIds);
  },
  DeleteDepartment:async(deletedDepartments:DepartmentFunctionArgs) => {
      //const {deletedIds} = deletedDepartments
     await DepartmentAction.DeleteDepartment(deletedDepartments);
  },
  GetUserAssignedDepartment:async():Promise<FetchReturn<{response:Department | undefined}>> => {
    const res =  await DepartmentAction.GetUserAssignedDepartment({});
    return res
  },
  CheckAlreadyReportedDepartment:async(reportCheck:DepartmentFunctionArgs):Promise<{[key:string]:CompressedDepartmentReported}> =>{
    const res = await DepartmentAction.CheckAlreadyReportedDepartment(reportCheck);
    return res.Content.response
  }
}
export const DataService = {
  InvoiceReports,
  Customers,
  Suppliers,
  Contracts,
  NullReports,
  News,
  Invoices,
  Users,
  Groups,
  Roles,
  Kickback,
  Dashboard,
  Logs,
  Departments
};
