/* eslint-disable @typescript-eslint/no-unused-vars */
import Urls from "../../lib/Urls";
import { Code, ReturnFetchObj, RoundTo, apiFetch, convertToQuerystring } from "../../lib/utils";
import { t } from 'i18next'
import { FieldProperty, InvoiceFields, InvoiceImportData } from "./InvoiceReports";
import { KickbackChangeState, KickbackChanged, ProjectReport, ProjectReportPolished, StatusObj } from "../../components/modal/SubmitKickbackProject";
import { Department } from "./Departments";

export interface KickbackReportPost {
  period: string;
  //comment: string;
  kickbackData: {
    contractId: string;
    sum: string;
    comment:string;
  }[];
  departmentId?:number;
}

export interface TKickback {
  reportId?:string;
  contractId: string;
  sumExclMoms: number;
  comment: string;
  period?: string;
  reportDate?: Date;
  reporter?: string;
  supplierOrgId?: string;
  kickbackProcent?:number;
  contractName?: string;
  contractType?: string;
  invoiceSumExclMoms?:number;
  status?:number;
  sumExclMomsChangesProps : FieldProperty;
}

export interface TKickbackReport {
  reportId: string;
  period: string;
  reportDate: Date;
  reporter: string;
  supplierOrgId: string;
  totalSum: number;
  totalKickback: number;
  noOfContracts: number;
  fileName?:string;
  status?:number;
  totalInvoiced:number;
  invoicedMatchReported?:boolean;
  type: TKickbackReportType;
}
export type TKickbackReportType = "Standard" | "Project";
export interface TKickbackStatusUpdate{
  reportId?:string;
  status?:number;
}


export interface KickbackWithFields extends TKickback {
  sumExclMomsProps : FieldProperty;
}
export interface TKickbackProjectReport{
  id:number;
  reportId:string;
  contractId:number;
  projectName:string;
  invoiceReference:string;
  invoiceIdentifier:string;
  kickbackSum:number;
  sumExclMoms:number;
  comment:string;
  period:Date;
  reportDate:Date;
  reporter:string;
  supplierOrgId:string;
  status:number;
  type:string;

}
export interface PariredProjectNameAndEndDate {
  [key:string]:Date|string| null
}
export type GroupedProjectNameAndEndDate = {
  [contractId:string]:PariredProjectNameAndEndDate 
}
// const GetKickbackProjectReports =  async ():Promise<any> => {
//   try{
//     const response = await apiFetch(Urls.KickbackReport+'getKickbackProjectItems')
//     const parse = response.json()
//     return parse
//   }catch(error){
//     console.log(error)
//   }
  

// }
const GetTypeaheadListsForKickbackProjectReports = async ():Promise<[string[],string[],string[],GroupedProjectNameAndEndDate,any[],any[]]> =>{
  const response = await apiFetch(Urls.KickbackReport+'/typeahedOptions');
  const parse = await response.json()
  const options:string[] = parse['projectNames'];
  const invoiceReferences:string[] = parse['invoiceReferences']
 // const projectEndDates:PariredProjectNameAndEndDate = parse['pairedProjectNamesAndEndDates']
  const tags:string[] = parse['tags']
  const test = parse['test']
  const customers = parse['customers']
  const contracts = parse['contracts']
  
  let ncnc:GroupedProjectNameAndEndDate = {}
  Object.entries(test).forEach(([key,value]) => {
    //console.log(key,value)
    if(!ncnc[key]){
      let contratdId:PariredProjectNameAndEndDate = {}
      const he:any[] = value as any[]
      he.reduce((contratdId,item) => {
        //console.log(item)
        if(!contratdId[item.pname]){
          contratdId[item.pname] = item.endDate
        }
        return contratdId
      },contratdId) 
      ncnc[key] = contratdId
    }
  })
  //console.log(ncnc)
  return [options,invoiceReferences,tags,ncnc,customers,contracts]

  
}
const Get = async (opt?: {year?:string, month?:string}) : Promise<TKickback[]> => {
  const queryString = convertToQuerystring(opt);
  const url = queryString.length > 0 ? `${Urls.KickbackReport}?${queryString}` : `${Urls.KickbackReport}`;
  const response = await apiFetch(url);
  const jsonKickbacks = await response.json();
  return jsonKickbacks.map((kickback:any) => {
    return {
      ...kickback,
      reportDate : new Date(kickback.reportDate),
      
    }
  })
}

export interface TKickbackReportByIdContract {
  contractId: string;
  invoicedSum: number;
  reportedSum: number;
  name: string;
  type:string;
  kickbackProcent:number;
  projectName?:string;
  invoiceReference?:string;
  invoiceIdentifier?:string;
  comment?:string;
  department?:Department;
   projectEndDate?:string;
   commentKickbackChange?:string;
}
export interface TKickbackReportByIdInvoices {
  invoiceNumber:string;
  contractId:string;
  customerOrgId:string;
  billingDate:Date;
  dueDate: Date;
  priceExcMoms: number;
  priceIncMoms: number;
  type: number;
}
export interface TKickbackReportById {
  status: number;
  reportDate: Date;
  reporter: string;
  reportId: string;
  supplierOrgId: string;
  comment: string;
  period: string;
  invoices: TKickbackReportByIdInvoices[];
  contracts: TKickbackReportByIdContract[];
  department:Department | null;
  kickbackChanges:KickbackChanged[] | null;
  // projectEndDate?:Date;
  // commentKickbackChanged?:string;
}
const GetKickbacksByReportId = async (reportId: string) : Promise<TKickbackReportById> => {
  const response = await apiFetch(Urls.KickbackReport+"/"+reportId);
  const jsonReport = await response.json();
  
  return {
    ...jsonReport,
    invoices: jsonReport.invoices.map((inv:any) => {
      return {
        ...inv,
        billingDate: new Date(inv.billingDate),
        dueDate: new Date(inv.dueDate)
      }
    }),
    reportDate: new Date(jsonReport.reportDate)
  };
}

const GetReports = async (opt?: {year?:string, month?:string, select?:string}) : Promise<TKickbackReport[]> => {
  const queryString = convertToQuerystring(opt);
  const url = queryString.length > 0 ? `${Urls.KickbackReport}/report?${queryString}` : `${Urls.KickbackReport}/report`;
  const response = await apiFetch(url);
  const jsonReports = await response.json();
  return jsonReports.map((item:any) => {
    const report : TKickbackReport = {
      ...item,
      reportDate: new Date(item.reportDate),
      type: "Standard",
      invoicedMatchReported: RoundTo(item?.totalSum, 3) == RoundTo(item?.totalInvoiced, 3) ?? false
    }
    return report;
  })
}

const Post = async (kickback : KickbackReportPost)  => {
  try {
    const res = await apiFetch(Urls.KickbackReport, "POST", JSON.stringify(kickback), {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.kickback.postSuccess'), Code.OK, {kickback});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.kickback.postFail'), Code.ADD_ERR, {kickback});
  }
}
const PostProjectReport = async(kickbackProjectReport:ProjectReportPolished[],invoices:any[],invoiceReportStatus:StatusObj,lang:string,departmentId?:number,kickbackChanged?:KickbackChangeState) => {
  try{
      const res = await apiFetch(Urls.KickbackReport + `/kickbackProjectReport`,'POST',JSON.stringify({kickbackProjectReport,invoices,invoiceReportStatus,lang,departmentId,kickbackChanged}),{"Content-Type": "application/json"})
      const parsedRes = await res.json()
      if(parsedRes.status !== 200){
        return ReturnFetchObj('testProjectReportSuccess',Code.ADD_ERR,{parsedRes})
      }
      return ReturnFetchObj('testProjectReportSuccess',Code.OK,{parsedRes})
    }catch(error){
      console.log(error)
     // return ReturnFetchObj('testProjectReportSuccess',Code.ADD_ERR,'')
  }
}
const Put = async (kickback : TKickbackStatusUpdate,type:TKickbackReportType)  => {
  try {
    const isProjectReport = type == 'Project';
    const res = await apiFetch(Urls.KickbackReport + `/updateKickBackStatus?kickbackProject=${isProjectReport}`  , "PUT", JSON.stringify(kickback), {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.kickback.postSuccess'), Code.OK, {kickback});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.kickback.postFail'), Code.ADD_ERR, kickback);
  }
}

const PostFile = async (file : File, period: string,comment:string) => {
  try {
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('period', period);
    formData.append('comment', comment);
    const res = await apiFetch(Urls.KickbackReport+"/file", "POST", formData);
    return ReturnFetchObj(t('api.kickback.postSuccess'), Code.OK, {file});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.kickback.postFail'), Code.ADD_ERR, {file});
  }
}
const UpdateFileStatus = async(fileName:string,supplierOrgId:string,status:number) => {
try{
  const query:string = `${Urls.KickbackReport}/updateKickbackFile?fileName=${fileName}&supplierOrgId=${supplierOrgId}&status=${status}` 
  const res = await apiFetch(query,"PUT",{"Content-Type": "application/json"})
  return ReturnFetchObj(t('api.kickback.updateSuccess'),Code.OK,{res})
}catch(error){
  console.log(error)
  return ReturnFetchObj(t('api.kickback.updateFail'),Code.UPDATE_ERR,{fileName})
}
}

const DeleteFile = async (fileName:string,supplierOrgId:string) => {
 try{
  const query:string = `${Urls.KickbackReport}/deleteFile?fileName=${fileName}&supplierOrgId=${supplierOrgId}` 
  
  const res = await apiFetch(query,"DELETE", {"Content-Type": "application/json"});
  
   return ReturnFetchObj(t('api.kickback.deleteSuccess'),Code.OK,{res})
 }catch (error){
  console.log(error)
  return ReturnFetchObj(t('api.kickback.deleteFail'),Code.DELETE_ERR,{fileName})
 }

}
const GetFile = async (fileName: string, supplierOrgNr: string) => {
  return apiFetch(`${Urls.KickbackReport}/${supplierOrgNr}/${fileName}`);
}

const GetMissedPeriods = async () : Promise<string[]> => {
  try {
    const response = await apiFetch(Urls.KickbackReport+"/missed");
    const missedPeriods = await response.json();
    return missedPeriods;
  } catch (error) {
    console.log(error);
    return [];
  }
}

const GetProjectReportById = async (reportId: string) : Promise<TKickbackReportById> => {
 console.log('JAJAJJAJAJJAJAJAJJA')
  const response = await apiFetch(Urls.KickbackReport+"/kickbackproject/"+reportId);
  const jsonReport = await response.json();
 
  return {
    ...jsonReport,
    invoices: jsonReport.invoices.map((inv:any) => {
      return {
        ...inv,
        billingDate: new Date(inv.billingDate),
        dueDate: new Date(inv.dueDate)
      }
    }),
    reportDate: new Date(jsonReport.reportDate)
  };
}
const GetKickbackProjectReports = async (compressed:boolean,reportId?:string):Promise<TKickbackProjectReport[]> => {
  try{
    //const url = typeof reportId !== 'undefined' ? `${Urls.KickbackReport}/kickbackproject?reportId=${reportId}` : `${Urls.KickbackReport}/kickbackproject`
    const url = `${Urls.KickbackReport}/kickbackproject?compressed=${compressed}&reportId=${reportId}`
    const res = await apiFetch(url)
   const jsonReports = await res.json();
   /*return jsonReports.map((item:any) => {
    const report : TKickbackProjectReport = {
      ...item,
      reportDate: new Date(item.reportDate),
      
      }
      return report;
      }*/  
     // console.log(jsonReports)
     
  if(compressed){
    const result = jsonReports.map((x:any) => {
     const buff = {
      noOfContracts:x.noOfContracts,
      reportId:x.reportId,
      totalSum:x.totalSum,
      totalKickback:x.totalKickback,
      supplierOrgId:x.supplierOrgId,
      reportDate:new Date(x.reportDate),
      type:x.type,
      period:x.period,
      reporter:x.reporter,
      comment:x.comment,
      status:x.status,
      //fileName:undefined
     }
     return buff
    })
    return result 
  }
  else{
    const reporterrrrrr = jsonReports.map((report:any) => {
      const parseee:TKickbackProjectReport = {
        id:report.id,
        contractId:report.contractId,
        invoiceIdentifier:report.invoiceIdentifier,
        invoiceReference:report.invoiceReference ,
        period: report.period,
        projectName:report.projectName,
        reportDate: new Date(report.reportDate),
        reportId:report.reportId,
        reporter:report.reporter,
        status:report.status,
        kickbackSum:report.kickbackSum,
        sumExclMoms:report.sumExclMoms,
        supplierOrgId:report.supplierOrgId,
        comment:report.comment,
        type:'project-report'
        
      }
      return parseee
    })
    return reporterrrrrr
  }
  
  //return jsonReports

  }catch(error){
    console.log(error)
    return [];
  } 
  
}

const ProjectReportSvg = (props?:{width?:string,height?:string}) => <svg
fill="#000"
xmlns="http://www.w3.org/2000/svg"
width={props?.width ?? "20px"}
height={props?.height ?? "18px"}
viewBox="0 0 204.504 204.504"
xmlSpace="preserve"
// {...props}
>
<path d="M99.542 43.211c10.875 0 19.704-12.623 19.704-23.501C119.246 8.823 110.417 0 99.542 0 88.654 0 79.825 8.829 79.825 19.71c0 10.879 8.829 23.501 19.717 23.501zM147.067 126.411h-5.59v-5.262c2.181-1.602 3.544-4.189 3.228-7.057l-6.235-56.118c-.049-.381-.243-.679-.341-1.041-.141-.572-.268-1.136-.536-1.659-.207-.393-.499-.697-.761-1.042-.348-.459-.664-.917-1.114-1.297-.354-.292-.768-.472-1.163-.703-.402-.237-.73-.554-1.182-.718-.706-.252-16.392-5.879-30.808-6.622l-1.982 3.967h-.049l5.249 40.879-6.235 10.89-6.235-10.89 5.252-40.879H98.5l-1.982-3.974c-14.416.743-30.099 6.366-30.802 6.622-.457.165-.792.493-1.19.731-.387.231-.801.399-1.142.691-.462.387-.792.862-1.154 1.337-.25.335-.53.63-.73 1.001-.274.53-.393 1.105-.548 1.69-.094.35-.286.648-.332 1.017l-6.235 56.118a7.793 7.793 0 007.758 8.653 7.793 7.793 0 007.733-6.93l5.678-51.118a125.82 125.82 0 012.165-.63v53.198l-6.205 77.138c-.411 5.151 3.425 9.657 8.573 10.071.256.019.505.03.761.03 4.82 0 8.917-3.708 9.31-8.604l5.605-69.722a9.315 9.315 0 003.772.805 9.285 9.285 0 003.771-.805l5.605 69.722c.39 4.896 4.487 8.604 9.311 8.604.256 0 .505-.006.761-.03 5.146-.414 8.981-4.926 8.573-10.071l-6.204-77.138V64.067c.773.219 1.491.429 2.161.63l5.682 51.118c.42 3.751 3.452 6.491 7.069 6.808v3.788h-5.584a3.123 3.123 0 00-3.117 3.117v47.276c0 1.717 1.4 3.117 3.117 3.117h16.368c1.711 0 3.117-1.4 3.117-3.117v-47.276c.021-1.711-1.393-3.117-3.098-3.117z" />
</svg>

export {
  Get,
  GetReports,
  GetKickbacksByReportId,
  Post,
  PostFile,
  GetFile,
  GetMissedPeriods,
  Put,
  DeleteFile,
  UpdateFileStatus,
  GetKickbackProjectReports,
  PostProjectReport,
  GetTypeaheadListsForKickbackProjectReports,
  GetProjectReportById,
  ProjectReportSvg
}