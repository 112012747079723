import Urls from "../../lib/Urls";
import { Code, ReturnFetchObj, apiFetch } from "../../lib/utils";
import { t } from 'i18next'
import { Invoice } from "./Invoices";
import { ProjectReport } from "../../components/modal/SubmitKickbackProject";
import { InvoicesWithCsrdAndGroupId } from "../../components/EditReports/editTypes";

export interface InvoiceImportData {
  // supplier: string;
  // supplierOrgId: string;
  id:number;
  customer: string;
  customerOrgId:string;
  contractId: string;
  invoiceNumber:string;
  billingDate:string;
  rowSumExclMoms:string;
  articleNumber:string;
  articleText:string;
  articleQuantity:string;
  articleQuantityType:string;
  articlePricePerUnit:string;
  //articleOriginalPricePerUnit:string;
  groupId:number;
}

export type InvoiceKeys = "billingDate" | "customer" | "customerOrgId" | 
  "invoiceNumber" | "rowSumExclMoms" | "contractId" | "articleNumber" |
  "articleText" | "articleQuantity" | "articleQuantityType" | "articlePricePerUnit" 

export const InvoiceGroupHeaders : InvoiceKeys[] = ["billingDate", "customer", "customerOrgId", 
"invoiceNumber", "contractId"]

export type FieldStatus = "Good" | "Warning" | "Error";
export interface FieldProperty {
  key: string;
  value: string;
  validation: FieldStatus;
  message: string;
  format: string;
  type: "Number" | "Text" | "Choice";
  options?:string[];
  elevatedError?:string;
}

export interface InvoiceFields {
  id:number;
  groupId:number;
  customer: FieldProperty;
  customerOrgId:FieldProperty;
  contractId: FieldProperty;
  invoiceNumber:FieldProperty;
  billingDate:FieldProperty;
  articleNumber:FieldProperty;
  articleText:FieldProperty;
  articleQuantity:FieldProperty;
  articleQuantityType:FieldProperty;
  articlePricePerUnit:FieldProperty;
  //articleOriginalPricePerUnit:FieldProperty;
  rowSumExclMoms:FieldProperty;
}

export interface InvoiceReport {
  id: number;
  comment: string;
  noOfInvoices: number;
  noOfInvoicesImported: number | null;
  noOfWarnings: number;
  totalSumExclMoms: number;
  totalSumExclMomsImported: number | null;
  reporter: string;
  supplierOrgId: string;
  supplierName?:string;
  reportDate: Date;
  invoicesFrom: Date;
  invoicesTo: Date;
  batchId: string;
  status: string;
  statusText: string;
  invoices?: Invoice[];
  filePath?:string;
  period:string;
  departmentId?:number;
  csrd?:boolean;
  csrdGroup?:InvoicesWithCsrdAndGroupId[];
  
}


const checkPeriod = (data:InvoiceReport):string => { 
  return (data.invoicesFrom.getFullYear() === data.invoicesTo.getFullYear() && data.invoicesFrom.getMonth() === data.invoicesTo.getMonth()) ?  data.invoicesFrom.getFullYear() + '-' + (data.invoicesFrom.getMonth() + 1).toString().padStart(2,"0") : `${data.invoicesFrom.toLocaleDateString('sv-SE')} - ${data.invoicesTo.toLocaleDateString('sv-SE')}`
}

const Get = async () : Promise<InvoiceReport[]> => {
  const response = await apiFetch(Urls.InvoiceReport);
  const jsonReports = await response.json();
  return jsonReports.map((item:any) => {
    const report : InvoiceReport = {
      ...item,
      reportDate : new Date(item.reportDate),
      invoicesFrom : new Date(item.invoicesFrom),
      invoicesTo : new Date(item.invoicesTo)
    }
    return report;
  })
}

const GetById = async (id:number) => {
  const response = await apiFetch(Urls.InvoiceReport+`/${id}`);
  const jsonReport = await response.json();
  const report : InvoiceReport = {
    ...jsonReport,
    reportDate : new Date(jsonReport.reportDate),
    invoicesFrom : new Date(jsonReport.invoicesFrom),
    invoicesTo : new Date(jsonReport.invoicesTo),
    invoices : (jsonReport?.invoices ?? []).map((inv:any) => ({
      ...inv,
      billingDate : new Date(inv.billingDate),
      dueDate : new Date(inv.dueDate),
      sendDate : new Date(inv.sendDate)
    }))
  }
  console.log(report)
  return report;

}

const Post = async (invoices : InvoiceImportData[], comment: string, noOfWarnings: number,departmentId?:number)  => {
  try {
    const res = await apiFetch(Urls.InvoiceReport, "POST", JSON.stringify({invoices, comment, noOfWarnings,departmentId}), {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.invoice.postSuccess'), Code.OK, {invoices, comment});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.invoice.postFail'), Code.ADD_ERR, {invoices, comment});
  }
}

const Delete = async (id: number) => {
  try {
    const res = await apiFetch(`${Urls.InvoiceReport}/${id}`, "DELETE");
    return ReturnFetchObj(t('api.invoice.deleteSuccess'), Code.OK, {id});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.invoice.deleteFail'), Code.DELETE_ERR, {id});
  }
}

const GetTemplates = async () : Promise<string[]> => {
  const res = await apiFetch(Urls.InvoiceReport+"/templates");
  return res.json();
}

const GetTemplateByName = async (fileName:string) => {
  return apiFetch(Urls.InvoiceReport+"/templates/"+fileName);
}

const IsAnyInProgress = async () => {
  try {
    const res = await apiFetch(Urls.InvoiceReport+"/inprogress");
    const inProgress = await res.json();

    return ReturnFetchObj("", Code.OK, {inProgress: inProgress});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.invoice.inProgressFail'), Code.GET_ERR, {inProgress: true});
  }
  
}

export {
  checkPeriod,
  Get,
  GetById,
  Post,
  GetTemplates,
  GetTemplateByName,
  Delete,
  IsAnyInProgress
}