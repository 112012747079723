

export default {
  Invoice :  "/Invoice",
  InvoiceReport : "/InvoiceReport",
  Customer : "/Customer",
  Supplier : "/Supplier",
  NullReport: "/NullReport",
  Contract: "/Contract",
  ContractCategory: "/ContractCategory",
  News: "/News",
  KcUsers: "/users",
  User: "/user",
  UserEmailAction: "/users/{id}/execute-actions-email?client_id=frontend-client&redirect_uri="+ window.location.origin,
  UserGroups: "/users/{id}/groups",
  UserGroupEdit: "/users/{id}/groups/{groupId}",
  UserRoles: "/users/{id}/role-mappings/realm",
  Groups: "/groups",
  GroupMembers: "/groups/{id}/members",
  Roles: "/roles",
  Impersonate: "/users/{id}/impersonation",
  KickbackReport: "/Kickback",
  Token: "/protocol/openid-connect/token",
  Dashboard: "/Dashboard",
  Logs: "/Logg",
  Department:"/Department"
}