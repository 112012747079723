/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import * as React from 'react'
import { DeleteModalState, EditReportType,GroupedDataByKey,/* GroupedDataByKey,*/ IEditReportProps,InvoicesWithCsrdAndGroupId,ReportDataMapping,/*,  InvoiceReportTextFileds,*/  /*InternalKeysForEIRT,*/  StandardInvoiceReport } from './editTypes'
//import { ListColumn } from '../Datalist/Datalist';
//import { ColumnDef } from "@tanstack/react-table";
//import Accordion from '../Accordion/StyledAccordion';
//import EditableTable, { GroupedCol } from "../EditableTable";
// import { EditInvoiceReportTable } from './EditInvoiceReportTable';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import i18n from '../../i18n';
//import { InvoiceReport } from '../../services/API/InvoiceReports';
import { Invoice } from '../../services/API/Invoices';
//import { ReadFriendlyNumber } from '../../lib/utils';
import { InvoiceReport } from '../../services/API/InvoiceReports';
//import { Infobar } from '../Infobar/Infobar';
//import { InfoBar } from './Info';
import { DataService } from '../../services/DataService';
import { Contract } from '../../services/API/Contracts';
import Spinner from 'react-bootstrap/esm/Spinner';
import { StandardInvoiceReportView } from './StandardInvoiceReportView';
//import { faClone, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { ColumnDef, CellContext, HeaderContext } from '@tanstack/react-table';
//import { Stack, Button } from 'react-bootstrap';
//import i18n from '../../i18n';
import { getSupplierOrgNr/*, ReadFriendlyNumber */} from '../../lib/utils';
import { Customer } from '../../services/API/Customer';
//import { _helpers } from '../../lib/ProjectReportValidation';

interface IEDitreportState{
  data?:/*GroupedDataByKey*/any;
  loading:boolean;
  contract:Contract[];
  deleteModal:DeleteModalState;
  customers:Customer[];
  //cols:ColumnDef<Invoice>[]
}
export class EditReport extends React.Component<IEditReportProps,IEDitreportState>{
  constructor(props:IEditReportProps){
    super(props)
    this.callBack = this.callBack.bind(this)
    this.invoiceReportOnChange = this.invoiceReportOnChange.bind(this)
    this.createRightTypeForReportDataInStateAndClone = this.createRightTypeForReportDataInStateAndClone.bind(this)
    this.copyRow = this.copyRow.bind(this)
    this.state = {
      data:undefined,
      loading:true,
      contract:[],
      deleteModal:{show:false,id:-1},
      customers:[]
      
    }
  }
 async componentDidMount(): Promise<void> {
   
    const customers = await DataService.Customers.Get({
      select:["isSupplier", "kndOrgnr", "kndNamn", "kndNamnKey", "kndLopnr"], 
      filter: "isSupplier eq false"
    })
    const contracts = await DataService.Contracts.GetBySupplierOrgNr(this.props.data[this.props.reportType].report.supplierOrgId)
    const res = structuredClone(this.props.data[this.props.reportType]);
   
    this.setState({data:res,contract:contracts,customers},() =>this.setState({loading:false},() => console.log('detta är state på init',this.state)))
   
  }
  handleDeleteModal = (show:boolean,id:number) => {
    this.setState({deleteModal:{show,id}})
  }
 handleTypeaheadBlur <T>(value:string,rowId:number,key:keyof T) {
    // let optionsAvailable:OptionsAvailable = _helpers.deepClone(this.state.optionsAvailable)
    //   optionsAvailable[key].find(x => x == value) ?? optionsAvailable[key].push(value)
    // if(value.length > 0){
    //   let stateAsOffNow:Period = _helpers.deepClone(this.state.periodTestData)
    //   let rowToUpdate:ProjectInvoice | undefined =  this.findRow(stateAsOffNow,rowId)
    //   if(rowToUpdate){
    //     switch (key){
    //       case 'projectName':{
    //         rowToUpdate[key] = value
    //         break;
    //       }
    //       case 'reference':{
    //         rowToUpdate[key] = value
    //         break;
    //       }
    //       case 'tag':{
    //         rowToUpdate[key] = value
    //         break;
    //       }
          
    //     }
    //     this.setState({periodTestData:stateAsOffNow,optionsAvailable})
    //   }
      
    // }
  }
  copyRow <T>(rowToCopy:T,index:number)  {
      if(this.props.reportType == 'Invoicereport'){
        //this is where an object should be copyed and added to an array
        const buff:StandardInvoiceReport = this.createRightTypeForReportDataInStateAndClone(this.props.reportType)
        const copiedItem:Invoice = structuredClone(rowToCopy) as Invoice
        copiedItem.id = copiedItem.id + 1
        buff.report.invoices?.push(copiedItem)
        this.setState({data:buff})
        console.log(rowToCopy)
      }else if(this.props.reportType == 'InvoicereportWithCSRD'){
        
      }
  }
  addRow = () => {
    if(this.props.reportType == 'Invoicereport'){
        const buff:StandardInvoiceReport = this.createRightTypeForReportDataInStateAndClone(this.props.reportType)
        //console.log(buff.report.invoices)
        const newInvoice:Invoice = {
              id:buff.report.invoices?.length ?? -1,    
              invoiceNumber: "",
              supplierOrgId: getSupplierOrgNr(),
              customerId: "",
              customerOrgId: "",
              contractId:"",
              priceExcMoms: 0,
              priceIncMoms: 0,
              isRemoved: false,
              billingDate: new Date(),
              noOfInvoices:1,
              dueDate: new Date(),
              sendDate: new Date(),
              customerName:"",
              type: 1,
              filePath: ""
          }
          buff.report.invoices?.push(
            newInvoice
        ) 
        this.setState({data:buff})
    }
    else if(this.props.reportType == 'InvoicereportWithCSRD'){

    }
  }
  deleteRow = (id:number) => {
    if(this.props.reportType == 'Invoicereport'){
      const buff:StandardInvoiceReport = this.createRightTypeForReportDataInStateAndClone(this.props.reportType)
      buff.report.invoices =  buff.report.invoices?.filter(x => x.id !== id)
      this.setState({data:buff,deleteModal:{show:false,id:-1}})
    }else if(this.props.reportType == 'InvoicereportWithCSRD'){
      const buff:StandardInvoiceReport = this.createRightTypeForReportDataInStateAndClone(this.props.reportType)
    }
  }
  callBack <T> (item:T,index?:number,key?:keyof T){
    const buff = this.createRightTypeForReportDataInStateAndClone(this.props.reportType)//structuredClone(this.state?.data) as StandardInvoiceReport
   //console.log('ändring på ingång',this.props.reportType == 'InvoicereportWithCSRD' && buff !== undefined && buff['report'] !== undefined && buff['report']?.csrdGroup !== undefined && index !== undefined && key == undefined,this.props.reportType == 'Invoicereport' && buff !== undefined && buff['report'] !== undefined && buff['report']?.invoices !== undefined && index !== undefined && key == undefined)
    if(this.props.reportType == 'Invoicereport' && buff !== undefined && buff['report'] !== undefined && buff['report']?.invoices !== undefined && index !== undefined && key == undefined){
    
      buff.report.invoices[index] = item as Invoice
    console.log(buff.report.invoices[index],item,index)
      this.setState({data:buff},() => console.log('här är state',this.state.data))
    }else if(this.props.reportType == 'InvoicereportWithCSRD' && buff !== undefined && buff['report'] !== undefined && buff['report']?.csrdGroup !== undefined && index !== undefined && key == undefined){
     
     
      const foundIndex = buff.report.csrdGroup.findIndex((x:InvoicesWithCsrdAndGroupId) => x.id == index)
      buff.report.csrdGroup[foundIndex]  = item as InvoicesWithCsrdAndGroupId
      this.setState({data:buff}/*,()=>console.log(this.state,foundIndex)*/)
     // console.log('utöka')
    }
  }
 invoiceReportOnChange(e:React.ChangeEvent<HTMLInputElement>,item?:InvoiceReport){
    const buff = structuredClone(this.state?.data) as StandardInvoiceReport
    if(buff !== undefined && buff['report'] !== undefined ){
      buff.report.comment = e.target.value
      this.setState({data:buff})
   
     }
  }
  createRightTypeForReportDataInStateAndClone <T extends EditReportType> (reportType:EditReportType):ReportDataMapping[T] {
      const buff:ReportDataMapping = {
        'Invoicereport':structuredClone(this.state?.data) as StandardInvoiceReport ?? {},
        'InvoicereportWithCSRD':structuredClone(this.state?.data) as StandardInvoiceReport ?? {},
        'Kickbackreport':{},
        'Logs':{},
        'Projectreport':{},
        'ProjectreportWithCSRD':{}
      }
      return buff[reportType]
  }
  returnBasedOnStandardReportContainsLogsOrNot = () => {
    const hasLogs = 'Logs' in this.state.data;
    return(
      <>
        { this.state.data !== undefined && this.props.reportType == 'Invoicereport'  &&
        <StandardInvoiceReportView customers={this.state.customers} contract={this.state.contract} copyRow={this.copyRow} handleDeleteModal={this.handleDeleteModal} deleteModal={this.state.deleteModal} deleteRow={this.deleteRow} addRow={this.addRow} data={this.state.data.report} reportType={this.props.reportType} logs={hasLogs ? this.props.data?.['Logs'] : undefined} invoiceReportOnChange={this.invoiceReportOnChange} callBack={this.callBack}/>
        }
      </>
    )
  }
  returnBasedOnStandardCsrdReportContainsLogsOrNot = () => {
    const hasLogs = 'Logs' in this.props.data;
    return(
      <>
        { this.state.data !== undefined && this.props.reportType ==  'InvoicereportWithCSRD'  &&
        <StandardInvoiceReportView customers={this.state.customers} contract={this.state.contract} copyRow={this.copyRow} handleDeleteModal={this.handleDeleteModal} deleteModal={this.state.deleteModal} deleteRow={this.deleteRow} addRow={this.addRow} data={this.state.data.report} reportType={this.props.reportType} logs={hasLogs ? this.props.data?.['Logs'] : undefined} invoiceReportOnChange={this.invoiceReportOnChange} callBack={this.callBack}/>
        }
      </>
    ) 
  }
  render(){
    return(<>
      <>
       {this.state.loading && 
       <div style={{display:'flex',flexDirection:'column',flexWrap:'wrap',alignContent:'center'}}>
       <Spinner/>
       </div>
       }
       
       {!this.state.loading && this.props.reportType == 'Invoicereport'   && typeof this.state.data !== 'undefined' &&          
         this.returnBasedOnStandardReportContainsLogsOrNot()
       }
      {!this.state.loading && this.props.reportType == 'InvoicereportWithCSRD' && typeof this.state.data !== 'undefined' && 
        this.returnBasedOnStandardCsrdReportContainsLogsOrNot()
      }
       </>
    </>)
  }
}










































































/*Implementera i mån av tid*/
const   SearchData = async (e: any,reportType:EditReportType,data:/*GroupedDataByKey*/any) => {  
    const res: any[] = [];
  
     setTimeout(async () =>{
      if (e.target.value === '') {
        //this.setState({searchedData:[],searched:false})
        return {searchedData:[],searched:false}
        
      } else {
       
        for (let index = 0; index < data.length; index++) {
          let val: boolean[] = [];
          // eslint-disable-next-line array-callback-return
          Object.values(data[index]).filter((value) => {
            if (typeof value === "string") {
              if (value.toLowerCase().includes(e.target.value.toLowerCase())) {
                val.push(true);
              }
            }
          });
          if (val.includes(true)) {
            res.push(data[index]);
          }
        }
      }
  
      if (res.length > 0) {
        //this.setState({ searchedData: res });
        return {searchedData:res,searched:true}
      }
      else{ 
        // this.setState({searchedData:[]})
        return {searchedData:[],searched:true}
      }
     },500)

   
  };