import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import i18n from "../../i18n"
import { InvoiceReport } from "../../services/API/InvoiceReports"
import { EditInvoiceReportTable } from "./EditInvoiceReportTable"
import { InfoBar } from "./Info"
import Accordion from '../Accordion/StyledAccordion';
import { Logs } from "../../services/API/Logs"
import Datalist, { ListColumn } from "../Datalist/Datalist"
import { DeleteModalState, HandleDeleteModal, InternalKeysForEIRT } from "./editTypes"
import { Contract } from '../../services/API/Contracts';
import { Customer } from "../../services/API/Customer"
interface StandardReportViewProps{
    data:InvoiceReport;
    invoiceReportOnChange:(e: React.ChangeEvent<HTMLInputElement>, item?: InvoiceReport)=>void;
    callBack:<T>(item:T,index?:number,key?:keyof T)=>void;
    reportType:InternalKeysForEIRT;
    addRow:()=>void;
    deleteRow:(id:number)=>void;
    handleDeleteModal:HandleDeleteModal;
    deleteModal:DeleteModalState;
    copyRow:<T>(item:T,index:number) => void;
    contract:Contract[];
    customers:Customer[];
    logs?:Logs[]
}
export const StandardInvoiceReportView = ({data,invoiceReportOnChange,callBack,reportType,addRow,deleteRow,handleDeleteModal,deleteModal,copyRow,contract,customers,logs}:StandardReportViewProps) => {
  console.log(data)
  const logColumns:ListColumn<Logs>[] = [
        {
            fieldName: "timeReported",
            isActive: true,
            key: "timeReported",
            minWidth: '',
            maxWidth: 150,
            sorting: true,
            name: 'timeReported',
            onRender:(item,col) => {
              return <p style={{width:'50px',margin:'0'}}>{new Date(item.timeReported).toLocaleDateString()}</p> 
                      
            }
            
          },
          {
            fieldName: "logLevel",
            isActive: true,
            key: "logLevel",
            minWidth: '',
            maxWidth: 100,
            //width:'35px !important',
            sorting: true,
            name: 'logLevel',
            
          },
          {
            fieldName: "logText",
            isActive: true,
            key: "logText",
            minWidth: '',
            //maxWidth: undefined,
            //width:'400px !important',
            sorting: true,
            name:'logText',
            onRender:(item,col) => {
              return <p style={{margin:'0'}}>{item.logText}</p>
            }
            
          }
        
    ]
   
   
    return(
      <>
           {/* {renderInfo()} */}
           <InfoBar data={data} handleChange={invoiceReportOnChange}/>
           {logs !== undefined && logs.length > 0 &&
           <Accordion open title={i18n.t("invoiceReportList.invoiceReportModal.logs").toString()} titleStyle={{fontSize: "1.1rem", margin: 0}} style={{padding: "0px 10px 0px"}} contentStyle={{paddingLeft: 4}}>
            <div style={{maxHeight: "48vh",marginBottom:'10px'}}>
            <Datalist
                items={logs ?? []}
                uniqueKey="invoiceNumber"
                columns={logColumns}
                noItemsText={i18n.t("invoiceReportList.invoiceReportModal.table.empty")}
                initialSortKey="timeReported"
                descendingSort={false}
                setColumns={(cols) => /*this.setState({ logColumns: cols })*/console.log(9)}
              />
              </div>
              </Accordion>
           }
         <Accordion open={true} title={i18n.t("invoiceReportList.invoiceReportModal.invoices").toString()} titleStyle={{fontSize: "1.1rem", margin: 0}} style={{padding: "0px 10px 0px 10px"}}>   
             <>
             <span>
             {" "}
             <input
               placeholder={/*`${i18n.t("dashboard.search")}`*/'Sök i fakturor....'}
               type="text"
               onChange={(e) => console.log() /*SearchData(e,this.props.reportType,this.state.data.report)*/}
             />
             <FontAwesomeIcon
               style={{ paddingLeft: "5px" }}
               icon="magnifying-glass"
             />
             <i style={{ paddingLeft: "5px" }}>
               {" "}
               {/* {this.state.searchedData.length > 0
                 ? `${this.state.searchedData.length} ${i18n.t(
                     "dashboard.hits"
                   )}`
                 : (this.state.searched ? i18n.t('dashboard.noHits'):'')} */}
            
             </i>
           </span>
             <EditInvoiceReportTable customers={customers} contract={contract} copyRow={copyRow} handleDeleteModal={handleDeleteModal} deleteModal={deleteModal} deleteRow={deleteRow} addRow={addRow} reportType={reportType} invoiceKey={reportType == 'Invoicereport' ? 'invoices' :'csrdGroup'} data={{...data}} callBack={callBack}/>
             </>
         </Accordion>   
         </>
    )
  
  }
