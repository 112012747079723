/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { StyledModal } from './StyledModal';
import {  Button, Dropdown, Spinner, Stack } from 'react-bootstrap';
import Accordion from '../Accordion/StyledAccordion';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { TKickbackReportById, TKickbackReportByIdContract, TKickbackReportByIdInvoices, TKickbackReportType, TKickbackStatusUpdate } from '../../services/API/Kickback';
import { DataService } from '../../services/DataService';
import Datalist, { FooterOptions, ListColumn } from '../Datalist/Datalist';
import { Code, ReadFriendlyNumber } from '../../lib/utils';
import { TSupplier } from '../../services/API/Suppliers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition,
IconLookup,
 } from '@fortawesome/fontawesome-svg-core';
import { API } from '../../services/API';
import { Invoice } from '../../services/API/Invoices';
import { Customer } from '../../services/API/Customer';
import Comment from '../Comment/Comment';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Contract } from '../../services/API/Contracts';
import { ProjectChangesView } from '../Reports/ProjectChangesView';



export interface IKickbackReportModalProps {
  onDismiss():void;
  kickbackReportId:string;
  handleUpdatedStatusSaveSuccess: (dissmiss:boolean,update:TKickbackStatusUpdate,info:any,status?:number) => void;
  selectedPeriod:string;
  supplier:string;
  type:TKickbackReportType;
}

type Status = {
  key:number;
  value:string;
  element:JSX.Element;
}
interface Statt{
  [key:number]:any
}
type ContractDict = {
  [supplierOrgId:string]:{[contractId:string]:{contractTypeId:number,contractName:string,contractType:string,kickbackPercent:number}}
}

export function KickbackReportModal (props: IKickbackReportModalProps) {
  const { t } = useTranslation();
  const [ loading, setLoading ] = useState(true);
  const [ kickbackReport, setKickbackReport ] = useState<Required<TKickbackReportById>>();
  const [ supplier, setSupplier ] = useState<TSupplier | undefined>(undefined);
  const okIcon = faCheckCircle;
  const notOkIcon = faExclamationCircle;
  const okColor = "#07a235"
  const tooMuchColor = "#f5a32d"
  const notEnoughColor = "#b30404"
  const [status,setStatus] = React.useState<Status | undefined>(undefined)
  const [statt,setStatt] = React.useState<any>()
  const [saving,setSaving] = React.useState(false)
  const [ columns, setColumns ] = useState<ListColumn<TKickbackReportByIdContract>[]>([
    {
      fieldName: "contractId",
      isActive: true,
      key: "contractId",
      minWidth: 100,
      sorting: true,
      isSorted: true,
      name: t('general.contract'),
      onRender(item, col) {
        return `${item.contractId} - ${item.type} - ${item.name}`
      },
      footer: FooterOptions.COUNT
    },
    {
      fieldName: "projectName",
      isActive: props.type == "Project",
      key: "projectName",
      minWidth: 100,
      sorting: true,
      name: t("kickbackProject.projectName")
    },
    {
      fieldName: "invoiceIdentifier",
      isActive: props.type == "Project",
      key: "invoiceIdentifier",
      minWidth: 100,
      sorting: true,
      name: t("kickbackProject.invoiceLabel")
    },
    {
      fieldName: "invoiceReference",
      isActive: props.type == "Project",
      key: "invoiceReference",
      minWidth: 100,
      sorting: true,
      name: t("kickbackProject.invoiceReference")
    },
    {
      fieldName: "reportedSum",
      isActive: true /*|| props.type == "Project" && typeof kickbackReport?.kickbackChanges == 'undefined' ? false:true*/,
      key: "reportedSum",
      minWidth: 60,
      sorting: true,
      name: t('kickback.table.header.sumExclMomsReported'),
      onRender: (item, col) => {
        let icon = okIcon;
        let color = okColor;
        if(item.reportedSum != item.invoicedSum){
          color = notEnoughColor;
          icon = notOkIcon;
        }
        if(item.reportedSum > item.invoicedSum){
          color = tooMuchColor;
        }
        return <div style={{ height: "100%", display:"flex", alignItems: "center"}}>
          {
            props.type !== "Project" &&
            <FontAwesomeIcon icon={icon} style={{marginRight: "8px", height: "18px", width: "18px"}} color={color}/>
          }
          {ReadFriendlyNumber(item.reportedSum)}
        </div>
      },
      footer: (items) => {
        const totalSum = items.reduce((prev, curr) => {
          return prev + curr.reportedSum;
        }, 0)
        return ReadFriendlyNumber(totalSum)
      }
    },
    {
      fieldName: "invoicedSum",
      isActive: props.type != "Project",
      sorting: true,
      key: "invoicedSum",
      minWidth: 60,
      name: t('kickback.table.header.invoiceSumExclMoms'),
      onRender: (item, col) => {
        return  ReadFriendlyNumber(item.invoicedSum);
      },
      footer: (items) => {
        const totalSum = items.reduce((prev, curr) => {
          return prev + curr.invoicedSum;
        }, 0)
        return ReadFriendlyNumber(totalSum)
      }
    },
    {
      fieldName: "kickbackProcent",
      isActive: true,
      sorting: true,
      key: "kickbackProcent",
      minWidth: 60,
      name: t('kickback.table.header.kickbackProcent'),
      onRender: (item,col) => {
        return item.kickbackProcent+"%"
      }
    },
    {
      fieldName: "calculated0",
      isActive: true /*|| props.type == "Project" && typeof kickbackReport?.kickbackChanges == 'undefined' ? false:true */,
      key: "calculated0",
      minWidth: 60,
      name: t('kickback.table.header.calculatedKickback'),
      onRender: (item,col) => {
        const kickbackNumber = item.kickbackProcent ?? 0;
        const calculatedNumber = item.reportedSum * (kickbackNumber/100);
        return ReadFriendlyNumber(calculatedNumber)
      },
      footer: (items) => {
        const kickbackTotal = items.reduce((prev, curr) => {
          const kickbackNumber = curr.kickbackProcent ?? 0;
          const calculatedNumber = curr.reportedSum * (kickbackNumber/100);
          return prev + calculatedNumber;
        }, 0)
        return ReadFriendlyNumber(kickbackTotal)
      },
      sorting: true,
      sort : (items, isSortedDescending) => {
        return items.sort((a, b) => {
          const kickbackNumberA = a.kickbackProcent ?? 0;
          const calculatedNumberA = a.reportedSum * (kickbackNumberA/100);
          const kickbackNumberB = b.kickbackProcent ?? 0;
          const calculatedNumberB = b.reportedSum * (kickbackNumberB/100);
          
          return (isSortedDescending ? calculatedNumberA < calculatedNumberB : calculatedNumberA > calculatedNumberB) ? 1 : -1;
        });
      }
    },
    {
      fieldName: "commentKickbackChange",
      isActive: props.type == "Project" && typeof kickbackReport?.kickbackChanges == 'undefined' ? false:true,
      key: "commentKickbackChange",
      minWidth: 100,
      sorting: true,
      name: /*t("kickbackProject.projectName")*/"Ev.ändring kommentar"
    },
    {
      fieldName: "calculated1",
      isActive: props.type != "Project",
      key: "calculated1",
      minWidth: 60,
      name: t('kickback.table.header.calculatedKickbackFromInvoice'),
      onRender: (item,col) => {
        const kickbackNumber = item.kickbackProcent ?? 0;
        const calculatedNumber = item.invoicedSum * (kickbackNumber/100);
        return ReadFriendlyNumber(calculatedNumber)
      },
      footer: (items) => {
        const kickbackTotal = items.reduce((prev, curr) => {
          const kickbackNumber = curr.kickbackProcent ?? 0;
          const calculatedNumber = curr.invoicedSum * (kickbackNumber/100);
          return prev + calculatedNumber;
        }, 0)
        return ReadFriendlyNumber(kickbackTotal)
      },
      sorting: true,
      sort : (items, isSortedDescending) => {
        return items.sort((a, b) => {
          const kickbackNumberA = a.kickbackProcent ?? 0;
          const calculatedNumberA = a.invoicedSum * (kickbackNumberA/100);
          const kickbackNumberB = b.kickbackProcent ?? 0;
          const calculatedNumberB = b.invoicedSum * (kickbackNumberB/100);
          
          return (isSortedDescending ? calculatedNumberA < calculatedNumberB : calculatedNumberA > calculatedNumberB) ? 1 : -1;
        });
      }
    },
   
    {
      fieldName: "projectEndDate",
      isActive: props.type == "Project",
      key: "projectEndDate",
      minWidth: 100,
      sorting: true,
      name: /*t("kickbackProject.projectName")*/"Projektslutdatum",
      onRender:(item,col) => {
        return item.projectEndDate?.split('T')[0] ?? ''
      }
    },
    {
      fieldName: "comment",
      isActive: /*props.type == "Project"*/true,
      key: "comment",
      minWidth: 100,
      name: t('general.comment')
    },
   
    
  ]);
  const [invoicesColumns,setInvoiceColumns] = React.useState<ListColumn<TKickbackReportByIdInvoices>[]>([])
 
  React.useEffect(() => {
    init();
    findIcons();
  }, []);

  React.useEffect(() => {initStatus();},[kickbackReport])

  const initStatus = () => {
   if(typeof kickbackReport !== 'undefined'){
    let initialstatus:any;
    for (let index = 0; index < AppSettings.kickbackReportsStatusIcons.length; index++) {
    const element = AppSettings.kickbackReportsStatusIcons[index];
    if(element.key === kickbackReport.status){
      const name:any = element.icon 
      const lookup:IconLookup = {prefix:'fas', iconName: name }
      initialstatus = {key:element.key,value:element.status,element:
      
        <> <FontAwesomeIcon  title={t(`kickback.status.${element.key}`) || ''}style={{color:`${element.color}`}} width={15} icon={findIconDefinition( lookup )}/>{' '} {t(`kickback.status.${element.key}`)} </>
      }
    }
    
   }
    setStatus(initialstatus)
   }
 
  }
  const findIcons = () => {
    const statt:Statt = {}
    AppSettings.kickbackReportsStatusIcons.map((x,index) => {
      const name:any = x.icon 
      const lookup:IconLookup = {prefix:'fas', iconName: name }
        statt[`${x.key}`] = {key:x.key,value: t(`kickback.status.${x.key}`),element:<> <FontAwesomeIcon  title={t(`kickback.status.${x.key}`)|| ''}style={{color:`${x.color}`}} width={15} icon={findIconDefinition( lookup )}/>{' '} {t(`kickback.status.${x.key}`)} </>} as Status 
    })
    setStatt(statt)
  }
  
  const init = async () => {
    const [customers, kickbackReport] = await Promise.all([
      DataService.Customers.Get(),
      props.type == "Project" ? 
      DataService.Kickback.GetProjectReportById(props.kickbackReportId)
      :
      DataService.Kickback.GetByReportId(props.kickbackReportId)
    ]);
    if(kickbackReport.invoices.length > 0){
      buildInvoiceColumns(customers)
    }
   
    const [ supplier ] = await Promise.all([
      DataService.Suppliers.GetByOrgNr(kickbackReport.supplierOrgId)
    ]);
    setKickbackReport(kickbackReport);
    setSupplier(supplier);
    setLoading(false);
  }

 const buildInvoiceColumns = (customers:Customer[]) => {
  const columns:ListColumn<TKickbackReportByIdInvoices>[] = [
    {
      fieldName: "invoiceNumber",
      isActive: true,
      key: "invoiceNumber",
      minWidth: 100,
      maxWidth: 140,
      sorting: true,
      name: t("invoiceReportList.invoiceReportModal.table.invoiceNumber"),
      footer: FooterOptions.COUNT
    },
    {
      fieldName: "contractId",
      isActive: true,
      key: "contractId",
      minWidth: 50,
      maxWidth: 140,
      name: t("invoiceReportList.invoiceReportModal.table.contractId"),
      sorting: true
    },
    {
      fieldName: "customerOrgId",
      isActive: true,
      key: "customerOrgId",
      sorting: true,
      minWidth: 100,
      name: t("invoiceReportList.invoiceReportModal.table.customer"),
      onRender: (item:Invoice) => {
        return `${item.customerOrgId} - ${customers.find(x => x.kndOrgnr == +item.customerOrgId)?.kndNamn}`;
      } 
    },
    {
      fieldName: "billingDate",
      isActive: true,
      key: "billingDate",
      minWidth: 150,
      maxWidth: 200,
      sorting: true,
      name: t("invoiceReportList.invoiceReportModal.table.billingDate"),
      onRender: (item:Invoice) => item.billingDate.toLocaleDateString()
    },
    {
      fieldName: "dueDate",
      isActive: true,
      key: "dueDate",
      minWidth: 150,
      maxWidth: 200,
      sorting: true,
      name: t("invoiceReportList.invoiceReportModal.table.dueDate"),
      onRender: (item:Invoice) => item.dueDate.toLocaleDateString()
    },
    {
      fieldName:"priceExcMoms",
      isActive: true,
      key: "priceExcMoms",
      minWidth: 20,
      maxWidth: 200,
      sorting: true,
      name: t("invoiceReportList.invoiceReportModal.table.priceExcMoms"),
      footer: FooterOptions.SUM,
      onRender: (item:Invoice) => ReadFriendlyNumber(item.priceExcMoms)
    },
    {
      fieldName:"priceIncMoms",
      isActive: true,
      key: "priceIncMoms",
      minWidth: 20,
      maxWidth: 200,
      sorting: true,
      name: t("invoiceReportList.invoiceReportModal.table.priceIncMoms"),
      footer: FooterOptions.SUM,
      onRender: (item:Invoice) => ReadFriendlyNumber(item.priceIncMoms)
    },
    {
      fieldName:"type",
      isActive: true,
      key: "type",
      minWidth: 20,
      maxWidth: 100,
      name: t("invoiceReportList.invoiceReportModal.table.type"),
      onRender: (item:Invoice) => item.type == 1 ? t("general.debit").toString() : t("general.credit").toString()
    }

  ]
  setInvoiceColumns( columns)
 }
  const renderInfo = () => {
    if(kickbackReport){
      return  <>
      <Stack style={{ justifyContent: "", flexWrap: "wrap" }}>
      
        <div style={{ fontSize: 18}}>{supplier?.supplierName+`(${supplier?.supplierOrgNr})`} - {new Date(kickbackReport.period).toLocaleDateString("sv-se", {month: "long", year: "numeric"})}</div>
       
        {
          props.type == "Project" &&
          <div style={{ fontSize: 16, fontStyle: "italic", color: "#878686"}}>{t("general.projectReport")}{API.Kickback.ProjectReportSvg({height:"100%", width:"24px"})}</div>
        }
         {typeof kickbackReport.department !== 'undefined' && kickbackReport.department !== null  &&
         <>
         <tr>
           
         <td>{t('departmentList.singular')}</td>
         <td style={{paddingLeft:'4px'}}><FontAwesomeIcon icon='building'/></td>
       </tr>
       <tr>
         <td style={{ fontSize: 16, fontStyle: "italic", color: "#878686"}}>{kickbackReport?.department.department}</td>
         </tr>
         </> 
        }
        {kickbackReport.reporter.includes('@') ? <a  href={`mailto:${kickbackReport.reporter}`}>{kickbackReport.reporter}</a> :<div style={{ fontSize: 14, fontStyle: "italic", color: "#878686"}}>{kickbackReport.reporter}</div>}
        <div style={{ fontSize: 14, fontStyle: "italic", color: "#878686"}}>{kickbackReport.reportDate.toLocaleDateString()}</div>
        {/* {
          kickbackReport.comment &&
          <Comment text={kickbackReport.comment} title={t("general.comment")}/>
        } */}
       
        </Stack>
      </>
    }
  }

 async function handleReportUpdatedStatusSave(){
  //stänger modalen props.onDismiss()
  setSaving(true)
  const reportUpdate : TKickbackStatusUpdate = {
    reportId: kickbackReport?.reportId,
    status: status?.key
  }
  const res = await API.Kickback.Put(reportUpdate, props.type);
  
  if(res.Code === Code.OK){
    setSaving(false)
    props.onDismiss()
    props.handleUpdatedStatusSaveSuccess(false,reportUpdate,res,res.Code)
  }
  else{
    //poppa upp error, fixa en spinner som ersätter save knappen, fixa till översättningar 
    //setShowDataError(true)
    props.handleUpdatedStatusSaveSuccess(false,reportUpdate,res,res.Code)
    setSaving(false)
  }
  
 }
 const renderInvoiceTable =  ():JSX.Element => {
  
  return   <div style={{maxHeight: "46vh",marginBottom:'10px'}}>
  <Datalist
      items={kickbackReport?.invoices ?? []}
      uniqueKey="invoiceNumber"
      columns={invoicesColumns}
      noItemsText={t("invoiceReportList.invoiceReportModal.table.empty")}
      initialSortKey="billingDate"
      descendingSort={false}
      setColumns={(cols) => setInvoiceColumns(invoicesColumns)}
    />
    </div>
 }
 
//  const compStyles = getComputedStyle(document.documentElement);
console.log(kickbackReport?.kickbackChanges)
  return (
    <StyledModal
      className='full-width'
      // headerStyle={{
      //   background: `${compStyles.getPropertyValue("--kickback-color")}`,
      //   color: "#ffffff"
      // }}
      onDismiss={() => props.onDismiss()}
      title={t('kickbackReportModal.title').toString()}
      body={
        <Stack gap={3}>
          {
            loading &&
            <Spinner/>
          }
          {
            !loading &&
            <>
            <Stack gap={3} direction='horizontal'>
              {renderInfo()}
                  <Dropdown>
                  <Dropdown.Toggle bsPrefix='newStyleToggle'>
                    {status?.element}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>                  
                  {Object.entries(statt).map(([x,value]) => {
                        return <Dropdown.Item key={x} id={`${x}`} onClick={() => setStatus(statt[`${x}`])}>{statt[`${x}`].element }</Dropdown.Item>
                      })}
                  </Dropdown.Menu>
                  </Dropdown>
               
                  </Stack>
              <Datalist
                items={kickbackReport?.contracts ?? []}
                uniqueKey="contractId"
                columns={columns}
                noItemsText={t("kickbackReportModal.table.empty")}
                initialSortKey="contractId"
                setColumns={(cols) => setColumns(cols)}
            />
            {
              kickbackReport && kickbackReport.contracts.length > 0 &&
              <Accordion title={t("invoiceReportList.invoiceReportModal.invoices").toString()} titleStyle={{fontSize: "1.1rem", margin: 0}} style={{padding: "0px 10px 0px 10px"}}>
              {kickbackReport.invoices.length > 0 ?  renderInvoiceTable() : <p>{t('kickbackReportModal.nothingFound')}</p>}
              </Accordion>
            }
            {
            typeof kickbackReport?.kickbackChanges?.length !== 'undefined' && kickbackReport?.kickbackChanges?.length > 0 && 
            <ProjectChangesView changes={kickbackReport?.kickbackChanges} kickbacReport={kickbackReport}/>
            }
            
          </>
          }
          
        </Stack>
      }
      footer={
        <>
        {/* {showDataError  && <span className="errorMessagePrint" style={{marginRight:'223px',marginBottom:'47px',position:'absolute'}} onClick={() => setShowDataError(false)}> <FontAwesomeIcon icon={faTriangleExclamation} color="red"/>
        {" "}Fel vid uppdatering{" "}<FontAwesomeIcon icon={faClose}/>
        </span>} */}
        {
          props.type != "Project" &&
          <Stack direction='horizontal' gap={2} style={{flexWrap: "wrap"}}>
            <div>
              <FontAwesomeIcon icon={okIcon} style={{marginRight: 6, color: okColor, width: "18px", height: "18px"}}/>
              {t('kickback.legend.equal')}
            </div>
            <div>
              <FontAwesomeIcon icon={notOkIcon} style={{marginRight: 6, color: notEnoughColor, width: "18px", height: "18px"}}/>
              {t('kickback.legend.notEnough')}
            </div>
            <div>
              <FontAwesomeIcon icon={notOkIcon} style={{marginRight: 6, color: tooMuchColor, width: "18px", height: "18px"}}/>
              {t('kickback.legend.tooMuch')}
            </div>
            
          </Stack>
         }
       
        {typeof status !== 'undefined' &&  
        
        <Button onClick={() => handleReportUpdatedStatusSave()} className='button-primary'>       
          {saving ? <Spinner/> : t('general.save')}
        </Button>}
        <Button onClick={() => props.onDismiss()} className='button-cancel'>
          {
            <>{t("general.close")}</>
          }
        </Button>
        </>
      }
      />
  );
}




